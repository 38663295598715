'use strict';

angular.module('cinecaLandingApp')
.factory('TipiDocIdentitaService', ['$resource',function ($resource) {
	return {
		external: $resource('api/public/tipi-doc-identita', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
		})
	};
}]);
