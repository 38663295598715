(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Service_trDeleteController',Service_trDeleteController);

    Service_trDeleteController.$inject = ['$uibModalInstance', 'entity', 'Service_tr'];

    function Service_trDeleteController($uibModalInstance, entity, Service_tr) {
        var vm = this;

        vm.service_tr = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Service_tr.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
