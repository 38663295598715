(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('rejectedRow', {
                parent: 'admin',
                url: '/rejectedRows',
                data: {
                    authorities: ['ROLE_LOG_ERRORE'],
                    pageTitle: 'cinecaLandingApp.rejectedRow.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/rejectedRow/rejectedRows.html',
                        controller: 'RejectedRowController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('rejectedRow');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('rejectedRow.detail', {
                parent: 'admin',
                url: '/rejectedRow/{id}',
                data: {
                    authorities: ['ROLE_LOG_ERRORE'],
                    pageTitle: 'cinecaLandingApp.rejectedRow.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/rejectedRow/rejectedRow-detail.html',
                        controller: 'RejectedRowDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('rejectedRow');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'RejectedRow', function($stateParams, RejectedRow) {
                        return RejectedRow.get({id : $stateParams.id});
                    }]
                }
            })
            .state('rejectedRow.new', {
                parent: 'rejectedRow',
                url: '/new',
                data: {
                    authorities: ['ROLE_LOG_ERRORE'],
                },
                onEnter: ['$stateParams', '$state', '$modal', function($stateParams, $state, $modal) {
                    $modal.open({
                        templateUrl: 'app/entities/rejectedRow/rejectedRow-dialog.html',
                        controller: 'RejectedRowDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    idJob: null,
                                    rejectedTypeId: null,
                                    payload: null,
                                    cause: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('rejectedRow', null, { reload: true });
                    }, function() {
                        $state.go('rejectedRow');
                    })
                }]
            })
            .state('rejectedRow.edit', {
                parent: 'rejectedRow',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_LOG_ERRORE'],
                },
                onEnter: ['$stateParams', '$state', '$modal', function($stateParams, $state, $modal) {
                    $modal.open({
                        templateUrl: 'app/entities/rejectedRow/rejectedRow-dialog.html',
                        controller: 'RejectedRowDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['RejectedRow', function(RejectedRow) {
                                return RejectedRow.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('rejectedRow', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('rejectedRow.delete', {
                parent: 'rejectedRow',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_LOG_ERRORE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/rejectedRow/rejectedRow-delete-dialog.html',
                        controller: 'RejectedRowDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['RejectedRow', function(RejectedRow) {
                                return RejectedRow.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('rejectedRow', null, { reload: 'rejectedRow' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();