'use strict';

angular.module('cinecaLandingApp').controller('ScheduledJobController', 
		['$scope','ScheduledJob','ScheduledJobStats','ParseLinks', function ($scope, ScheduledJob, ScheduledJobStats, ParseLinks) {
        $scope.scheduledJobs = [];
        $scope.page = 0;
        
        $scope.statusFilter = "";
        
        $scope.filterStatusList = [];
        
        $scope.getStatusList = function(){
        	ScheduledJobStats.query({}, function(result, headers) {
        		$scope.filterStatusList = result;
        	});
        };
        
        $scope.loadAll = function() {
            //ScheduledJob.query({page: $scope.page, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'desc' : 'asc'), 'id']}, function(result, headers) {
        	ScheduledJob.query({page: $scope.page, size: 20, sort:'id,desc', statusFilter:$scope.statusFilter}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.scheduledJobs = result;
            });
        };
      
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.getStatusList();

        $scope.delete = function (id) {
            ScheduledJob.get({id: id}, function(result) {
                $scope.scheduledJob = result;
                $('#deleteScheduledJobConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            ScheduledJob.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteScheduledJobConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.scheduledJob = {
                dateStart: null,
                dateEnd: null,
                status: null,
                jobName: null,
                newRecords: null,
                changedRecords: null,
                deletedRecords: null,
                identicalRecords: null,
                rejectedRecords: null,
                id: null
            };
        };
    }]);
