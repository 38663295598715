(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('ProbePolicyDialogController', ProbePolicyDialogController);

    
    
    
    
    ProbePolicyDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'pagingParams', 'paginationConstants', 'AlertService','Service', 'UserAsset'];

    function ProbePolicyDialogController ($timeout, $scope, $state, $stateParams, pagingParams, paginationConstants, AlertService, Service, UserAsset) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.currentSearch = pagingParams.search;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.loadPage = loadPage;
        vm.transition = transition;
        
        vm.loadAll = function() {
        	//if (vm.currentSearch.selectedService) {
        		UserAsset.get({
            		page: pagingParams.page - 1,
            		size: vm.itemsPerPage,
            		sort: sort(),
            		filterUser: vm.currentSearch ? vm.currentSearch.filterUser : null,
            		filterService: vm.currentSearch ? vm.currentSearch.selectedService : null
            	}, onSuccess, onError);
//        	} else {
//                vm.users = [];
//        	}
        };
        vm.loadAll();
        
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: JSON.stringify(vm.currentSearch)
            });
        }
        
        Service.getAll({}, function(result){
        	vm.listaServizi = result;
        }, function(response){
        	console.log(response);
        });
        
        vm.selectService = function ($item, $model, $label){
        	console.log($item);
        	vm.loadAll();
        };
        
        $scope.$watch('vm.currentSearch.filterUser', function (newValue) {
        	vm.loadAll();
        });

        vm.clear = function() {
            $state.go('policy');
        }

        
    }
})();
