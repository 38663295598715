'use strict';

angular.module('cinecaLandingApp').controller('FornitoriDialogController',
		['$scope', '$stateParams', '$uibModalInstance', 'entity', 'tipologieFornitoriList','durataList','tipiDocIdentitaList','Esterni','CodiceFiscaleService','ComuniCodiceFiscaleService','$q','DATE_PICKER_MIN','DATE_PICKER_MAX', 
	        function($scope, $stateParams, $uibModalInstance, entity, tipologieFornitoriList, durataList,tipiDocIdentitaList,Esterni, CodiceFiscaleService, ComuniCodiceFiscaleService,$q,DATE_PICKER_MIN,DATE_PICKER_MAX) {

    	var ctrl = this;
    	$scope.countryList = null;
    	$scope.genderList = ['M','F'];
    	$scope.checkIfDateIsBeforeToday = checkIfDateIsBeforeToday;
        $scope.checkIfDateIsInRange = checkIfDateIsInRange;

    	//Tipologie Esterni
    	$scope.tipologieFornitoriList = tipologieFornitoriList;
    	//Tipologie doc identità
    	$scope.tipiDocIdentitaList = tipiDocIdentitaList;
    	//Durata
    	$scope.durataList = durataList;
    	
    	$scope.dateFormat = "dd/MM/yyyy";
    	$scope.minDateAllowed = new Date(DATE_PICKER_MIN);
    	$scope.maxDateAllowed = new Date(DATE_PICKER_MAX);

    	function checkIfDateIsBeforeToday(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
        	var today = new Date();
        	today.setHours(0,0,0,0);
    		if(dateToVerify>=today)
    			deferred.resolve(isValid);
    		else
    			deferred.reject(false);
    			
    		
    		return deferred.promise;
    	};
    	
    	function checkIfDateIsInRange(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
    		if(dateToVerify<$scope.minDateAllowed || dateToVerify>$scope.maxDateAllowed)
    			deferred.reject(false);
    		else
    			deferred.resolve(isValid);
    		
    		return deferred.promise;
    	};
    	
    	if(entity.dataNascita!=null)
    		entity.dataNascita = new Date(entity.dataNascita);
    	if(entity.dataInizio!=null)
    		entity.dataInizio = new Date(entity.dataInizio);
    	else
    		entity.dataInizio = new Date();
    	if(entity.dataValiditaDocumento!=null)
    		entity.dataValiditaDocumento = new Date(entity.dataValiditaDocumento);
    	
    	$scope.esterni = entity;
    	
    	$scope.today = new Date();
    	$scope.today.setDate($scope.today.getDate() - 1);
    	
    	Esterni.getCountryList({},function(response){
        	$scope.countryList = response;
        });
    	
    	
    	
    	if($scope.esterni.id != null)
    		$scope.savedCodFis = $scope.esterni.codiceFiscale;
    	else
    		$scope.savedCodFis = null;
       
        $scope.esterniCopy = {};
      
        
        if ( $stateParams.id !== undefined ) {
	        entity.$promise.then(function( ent ) {
	        	//entity.provResidenza = provinciaSelected;
	        	angular.copy(ent, $scope.esterniCopy);
	        });
        }

        $scope.load = function(id) {
            Esterni.get({id : id}, function(result) {
                $scope.esterni = result;
              
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('cinecaLandingApp:esterniUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function (formIsValid) {
        	if(formIsValid){
	            if ($scope.esterni.id != null) {
	                Esterni.update($scope.esterni, onSaveFinished);
	            } else {
	                Esterni.save($scope.esterni, onSaveFinished);
	            }
        	}
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        
        $scope.checkUniqueCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var payload = {
					"documentoIdentita": null,
					"codiceFiscale" : codfis,
					"nazionalita":"IT"
			}; 
        	
        	CodiceFiscaleService.checkUniqueCodiceFiscale.check( payload, function(resp) {
        		isValid = resp.isUnique;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		//Il codice fiscale esiste perchè è quello dell'utente corrente.
        		else if($scope.savedCodFis != null && $scope.savedCodFis === codfis){
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        
        $scope.checkCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkCodiceFiscale.check( codfis, function(resp) {
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        $scope.checkDateCodiceFiscale = function(dataNascita, codiceFiscale) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var inputDate =  (dataNascita == null ? new Date() : dataNascita);
        	var payload = {
					"dataNascita": transformDate( inputDate ),
					"codiceFiscale" : codiceFiscale
			}; 
        	CodiceFiscaleService.checkDateCodiceFiscale.check( payload, function(resp) {
        	
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        $scope.checkGenderCodFiscale = function(gender,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	
        	if(codiceFiscale.length>=10){
        		var giornoNascita = codiceFiscale.substring(9,11);
        		if( (giornoNascita>=40 && gender==='F') || (giornoNascita<40 && gender==='M') ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}
        	return deferred.promise;
        };
        
        $scope.checkLuogoCodFiscale = function(luogo,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	if(codiceFiscale.length==16 && luogo!=null){
        		var codiceComune = codiceFiscale.substring(11,15);
        		if(codiceComune===luogo ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        		
        	}
        	else{
        		isValid = true;
    			deferred.resolve(isValid);
        	}
        	
        	return deferred.promise;
        }
        
        
        $scope.isActive = function(){
        	if($scope.esterni!=null && $scope.esterni.flAttivo==1)
        		return true;
        	return false;
        }
          
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        ctrl.preventKeyboard = function($event) {
    		$event.preventDefault();
    	}
        
        $scope.updateLuogoNascitaSelezionato = function(luogo){
        	$scope.luogoNascitaSelezionato = luogo.codice;
        }
//        $scope.appendSiglaProvincia = function(siglaProvincia){
//        	if(siglaProvincia!=null)
//        		return " ("+siglaProvincia+")";
//        	else
//        		return "";
//        }
        
      
}]);
