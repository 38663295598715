(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal','$stateParams','$http','$timeout','$window'];

    function PasswordController (Auth, Principal,$stateParams,$http,$timeout,$window) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.errorOldPassword = null;
        vm.errorPPolicy = null;
        vm.us_account=$stateParams.us_account;

        if(vm.us_account){
        	vm.account = {};
        	getUserName();
        	
        }else{
	        Principal.identity().then(function(account) {
	            vm.account = account;
	        });
        }
        
        function getUserName(){
        	$http.get('api/account/whois',{
        	    params: {us_account: vm.us_account}
        	 }).then(function(result) {
                
        		if(result.status == 200){
        			vm.account = result.data;
        		}
                
            });

        };
        
        function goToAuth(){
        	$window.location.href = 'auth/'; 
        }

        function changePassword () {
            vm.error = null;
            vm.success = null;
            vm.doNotMatch = null;
            vm.errorPPolicy = null;
            vm.errorOldPassword = null;
            vm.errorPasswordInHistory = null;
            vm.errorPasswordTooYoung = null;
            if (vm.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else if (PASSWORD_PATTERN && !vm.password.match(PASSWORD_PATTERN)) {
            	vm.errorPPolicy = 'ERROR';
            } else {
                Auth.changePassword(vm.oldPassword,vm.password,vm.us_account).then(function () {
                    vm.success = 'OK';
                    if($stateParams.us_account!=null){
                    	$timeout(goToAuth, 5000);
                    }
                    
                }).catch(function (response) {
                	
                	if(response.data === 'IncorrectOldPassword')
                		vm.errorOldPassword = 'IncorrectOldPassword';
                	else if(response.data === 'InvalidPasswordInHistory') {
                		console.log("PippoFranco");
                		console.log(vm);
                		console.log(response.data);
                		vm.errorPasswordInHistory = 'InvalidPasswordInHistory';
                	}
                	else if(response.data.match(/^InvalidPassword.*/)) {
                		vm.errorPPolicy = response.data;
                		vm.errorOldPassword = response.data;
                	}
                	else
                		vm.error = 'ERROR';
                });
            }
        }
    }
})();
