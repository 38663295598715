(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Iso_country_codesDetailController', Iso_country_codesDetailController);

    Iso_country_codesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Iso_country_codes'];

    function Iso_country_codesDetailController($scope, $rootScope, $stateParams, previousState, entity, Iso_country_codes) {
        var vm = this;

        vm.iso_country_codes = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('cinecaLandingApp:iso_country_codesUpdate', function(event, result) {
            vm.iso_country_codes = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
