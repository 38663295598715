'use strict';

angular.module('cinecaLandingApp').controller('RejectedRowDialogController',
    ['$scope', '$stateParams', '$modalInstance', 'entity', 'RejectedRow', 'RejectedType',
        function($scope, $stateParams, $modalInstance, entity, RejectedRow, RejectedType) {

        $scope.rejectedRow = entity;
        $scope.rejectedtypes = RejectedType.query();
        $scope.load = function(id) {
            RejectedRow.get({id : id}, function(result) {
                $scope.rejectedRow = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('cinecaLandingApp:rejectedRowUpdate', result);
            $modalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.rejectedRow.id != null) {
                RejectedRow.update($scope.rejectedRow, onSaveFinished);
            } else {
                RejectedRow.save($scope.rejectedRow, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $modalInstance.dismiss('cancel');
        };
}]);
