(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('registryEntities', {
                parent: 'app',
                url: '/registryEntities',
                data: {
                    authorities: ['ROLE_REGISTRY'],
                    pageTitle: 'cinecaLandingApp.registryEntities.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/registry/registryEntities.html',
                        controller: 'RegistryEntitiesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registryEntities');
                        $translatePartialLoader.addPart('provider_tr');
                        $translatePartialLoader.addPart('product_tr');
                        $translatePartialLoader.addPart('module_tr');
                        $translatePartialLoader.addPart('service_tr');
                        
                        $translatePartialLoader.addPart('provider');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('module');
                        $translatePartialLoader.addPart('service');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
