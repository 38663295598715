'use strict';

angular.module('cinecaLandingApp').controller('ScheduledJobDetailController', 
		['$scope', '$rootScope', '$stateParams', 'entity', 'ScheduledJob' ,function ($scope, $rootScope, $stateParams, entity, ScheduledJob) {
        $scope.scheduledJob = entity;
        $scope.load = function (id) {
            ScheduledJob.get({id: id}, function(result) {
                $scope.scheduledJob = result;
            });
        };
        $rootScope.$on('cinecaLandingApp:scheduledJobUpdate', function(event, result) {
            $scope.scheduledJob = result;
        });
    }]);
