'use strict';

angular.module('cinecaLandingApp')
.factory('StruttureService', ['$resource',function ($resource) {
	return {
		external: $resource('api/strutture', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'getFromUser': { method: 'GET',
				params: {},
				url:'api/strutture-from-user',
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'getTree': { method: 'GET',
				params: {},
				url:'api/public/albero-strutture',
				isArray: false,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
		})
	};
}]);
