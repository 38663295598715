(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('ProbeSchedulerController', ProbeSchedulerController);

    
    
    
    
    ProbeSchedulerController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'pagingParams', 'paginationConstants', 'AlertService','Service', 'AssetProbe'];

    function ProbeSchedulerController ($timeout, $scope, $state, $stateParams, pagingParams, paginationConstants, AlertService, Service, AssetProbe) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.probes = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.loadPage = loadPage;
        vm.transition = transition;
        
        vm.timer;
        
        vm.loadAll = function() {
        	$timeout.cancel(vm.timer);
    		AssetProbe.get({
        		page: pagingParams.page - 1,
        		size: vm.itemsPerPage,
        		sort: sort()
        	}, onSuccess, onError);
    		if (pagingParams.page == 1) {
    			vm.timer = $timeout(vm.loadAll, 10000);
    		}
        };
        vm.loadAll();
        
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.probes = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }
        
        AssetProbe.getSettings({}, function(result){
        	vm.settings = result;
        }, function(response){
        	console.log(response);
        });
        
        vm.saveSettings = function() {
        	AssetProbe.putSettings(vm.settings, function(result){
            	vm.success = true;
            	$timeout(function() {
            		vm.success = null;
				}, 10000);
            }, function(response){
            	console.log(response);
            });
		};
        
        vm.startManually = function() {
        	AssetProbe.start({}, function(result){
            	vm.startedManually = true;
            	$timeout(function() {
            		vm.startedManually = null;
				}, 10000);
            }, function(response){
            	console.log(response);
            });
		};
        
//        $scope.$watch('vm.selectedService', function (newValue) {
//        	console.log("TYPING: "+ newValue);
//        });

        vm.clear = function() {
            $state.go('policy');
        }

        
    }
})();
