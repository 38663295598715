(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Service_trDialogController', Service_trDialogController);

    Service_trDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Service_tr', 'Service', 'LANGUAGES'];

    function Service_trDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Service_tr, Service, LANGUAGES) {
        var vm = this;

        vm.service_tr = entity;
        vm.clear = clear;
        vm.save = save;
        vm.services = Service.query();
        vm.languages = LANGUAGES;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.service_tr.id !== null) {
                Service_tr.update(vm.service_tr, onSaveSuccess, onSaveError);
            } else {
                Service_tr.save(vm.service_tr, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:service_trUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
