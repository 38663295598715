(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('JiraRegisterController', JiraRegisterController);


    JiraRegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService','Esterni','CodiceFiscaleService','tipiDocIdentitaList','$q','CategorieService','ComuniCodiceFiscaleService','DATE_PICKER_MIN','DATE_PICKER_MAX'];

    function JiraRegisterController ($translate, $timeout, Auth, LoginService, Esterni,CodiceFiscaleService,tipiDocIdentitaList,$q,CategorieService,ComuniCodiceFiscaleService,DATE_PICKER_MIN,DATE_PICKER_MAX) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.registerAccount = {};
        vm.success = null;
        vm.checkIfDateIsBeforeToday = checkIfDateIsBeforeToday;
        vm.checkIfDateIsInRange = checkIfDateIsInRange;
        vm.checkIf16YearsOld = checkIf16YearsOld;
        vm.tipiDocIdentitaList = tipiDocIdentitaList;
        vm.btAvantiClicked = false;
        
        vm.dateFormat = "dd/MM/yyyy";
    	vm.minDateAllowed = new Date(DATE_PICKER_MIN);
    	vm.maxDateAllowed = new Date(DATE_PICKER_MAX);
    	
    	function checkIfDateIsBeforeToday(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
        	var today = new Date();
        	today.setHours(0,0,0,0);
    		if(dateToVerify>=today)
    			deferred.resolve(isValid);
    		else
    			deferred.reject(false);
    			
    		
    		return deferred.promise;
    	};
    	
    	function checkIfDateIsInRange(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
    		if(dateToVerify<vm.minDateAllowed || dateToVerify>vm.maxDateAllowed)
    			deferred.reject(false);
    		else
    			deferred.resolve(isValid);
    		
    		return deferred.promise;
    	};
    	
    	function checkIf16YearsOld(dateOfBirth){
    		
        	var ageDifMs = Date.now() - dateOfBirth.getTime();
        	var ageDate = new Date(ageDifMs);// miliseconds from epoch
     	    var age = (Math.abs(ageDate.getUTCFullYear() - 1970));
    		
     	    if(age>=16)
    			return true;
    		else
    			return false;

    	};


        /**** Registrazione utente esterno ****/
        
        vm.utenteEsterno = {"fl_attivo":0, "codice_fiscale":null,documento_identita:null};
        vm.genderList = ['M','F'];
        vm.countryList = null;
        vm.savedCodFis = null;
        getCategorieList();
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        Esterni.getCountryList({},function(response){
        	vm.countryList = response;
        });
        
        
        vm.createAccount = function(){
        	vm.utenteEsterno.luogoDiNascita = vm.luogoNascitaSelezionato.denominazioneComune;
        	vm.utenteEsterno.fonte = 'JIRA';
        	//Va verificato che l'utente non sia già presente
        	Esterni.save(vm.utenteEsterno, onSaveFinished, onSaveError);
        };
        
        var onSaveFinished = function (result) {
            //Va mostrata una pagina con scritto "hai ricevuto un email..per confermare..."
        	vm.success = true;
        };
        
        var onSaveError = function (result) {
            //Va mostrata una pagina con scritto "hai ricevuto un email..per confermare..."
        	vm.success = false;
        };
        
        //Verifica che non esista già un esterno con il codice fiscale inserito
        vm.checkUniqueCodiceFiscale = function(codFis,docIdentita) {
        	if(codFis==null || codFis.length==0)
        		return true;
        	
        	var reqObject = {
        		codiceFiscale : codFis,
        		documentoIdentita: docIdentita,
        		nazionalita: vm.utenteEsterno.nazionalita
        	};
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkUniqueCodiceFiscale.check( reqObject, function(resp) {
        		isValid = resp.isUnique;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		//Il codice fiscale esiste perchè è quello dell'utente corrente.
        		else if(vm.savedCodFis != null && vm.savedCodFis === codfis){
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        //Verifica la validità del codice fiscale
        vm.checkCodiceFiscale = function(codFis) {
        	if(codFis==null || codFis.length==0)
        		return true;
        	
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkCodiceFiscale.check( codFis, function(resp) {
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        //Verifica la validità del codice fiscale rispetto alla data di nascita inserita
        vm.checkDateCodiceFiscale = function(dataNascita, codiceFiscale) {
        	if(codiceFiscale==null || codiceFiscale.length==0)
        		return true;
        	
        	var isValid = false;
        	var deferred = $q.defer();
        	var inputDate =  (dataNascita == null ? new Date() : dataNascita);
        	var payload = {
					"dataNascita": transformDate( inputDate ),
					"codiceFiscale" : codiceFiscale
			}; 
        	CodiceFiscaleService.checkDateCodiceFiscale.check( payload, function(resp) {
        	
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        vm.changeStep = function(value,formIsValid){
        	vm.btAvantiClicked = true;
        	if(formIsValid){
        		vm.stepConfirmation = value;
        		if(value===true){
        			vm.isAtLeast16YearsOld = checkIf16YearsOld(vm.utenteEsterno.dataNascita);
        		}
        	}
        }
        
        //Verifica la validità del codice fisfale rispetto al genere inserito
        vm.checkGenderCodFiscale = function(gender,codiceFiscale){
        	if(codiceFiscale==null || codiceFiscale.length==0)
        		return true;
        	
        	var isValid = false;
        	var deferred = $q.defer();
        	
        	if(codiceFiscale.length>=10){
        		var giornoNascita = codiceFiscale.substring(9,11);
        		if( (giornoNascita>=40 && gender==='F') || (giornoNascita<40 && gender==='M') ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}
        	return deferred.promise;
        };
        
        vm.checkLuogoCodFiscale = function(luogo,codiceFiscale){
        	if(codiceFiscale==null || codiceFiscale.length==0)
        		return true;
        	
        	var isValid = false;
        	var deferred = $q.defer();
        	if(codiceFiscale.length==16 && luogo!=null){
        		var codiceComune = codiceFiscale.substring(11,15);
        		if(codiceComune===luogo ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        		
        	}
        	else{
        		isValid = true;
    			deferred.resolve(isValid);
        	}
        	
        	return deferred.promise;
        }
        
        vm.updateLuogoNascitaSelezionato = function(luogo){
        	vm.luogoNascitaSelezionato = luogo.codice;
        }
        
        
        function getCategorieList(){
        	CategorieService.external.get({}, function(resp) {
        		vm.categoryList = resp;
        		for(var i=0;i<resp.length;i++){
        			if(resp[i].sottoCategoria.toUpperCase().indexOf("JIRA")>-1){
        				vm.utenteEsterno.categoria = resp[i].id;
        				break;
        			}
        		}
        	}, function(err) {
        		deferred.reject(false);
        	});
        }
        
        function getElencoComuni(){
        	ComuniCodiceFiscaleService.query({}, onSuccess, onError);

        	function onSuccess(data, headers) {
        		vm.listaComuni = data;

        	}
        	function onError(error) {
        		AlertService.error(error.data.message);
        	}
        }
    }
})();
