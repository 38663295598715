(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Iso_country_codesController', Iso_country_codesController);

    Iso_country_codesController.$inject = ['Iso_country_codes'];

    function Iso_country_codesController(Iso_country_codes) {

        var vm = this;

        vm.iso_country_codes = [];

        loadAll();

        function loadAll() {
            Iso_country_codes.query(function(result) {
                vm.iso_country_codes = result;
                vm.searchQuery = null;
            });
        }
    }
})();
