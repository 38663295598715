'use strict';

angular.module('cinecaLandingApp')
    .controller('ConfigParamController', 
    		['$scope','ConfigParam','ParseLinks',function ($scope, ConfigParam, ParseLinks) {
        $scope.configParams = [];
        $scope.page = 0;
        $scope.loadAll = function() {
            ConfigParam.query({page: $scope.page, size: 20, sort:'name,asc'}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.configParams = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            ConfigParam.get({id: id}, function(result) {
                $scope.configParam = result;
                $('#deleteConfigParamConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            ConfigParam.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteConfigParamConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.configParam = {
                configurationId: null,
                name: null,
                value: null,
                id: null
            };
        };
    }])
    .filter('shortenValue', ['$filter',function($filter) {
    	return function(input, threeshold) {
    		var output = input;
    		if ( input != null && input.length > threeshold ) {
        		output = $filter('limitTo')(input, threeshold) + " ...";
        	}
        	return output;
    	}
    }]);
