(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('configParam', {
                parent: 'admin',
                url: '/configParams',
                data: {
                    authorities: ['ROLE_CONFIGURAZIONE_PARAM'],
                    pageTitle: 'cinecaLandingApp.configParam.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/configParam/configParams.html',
                        controller: 'ConfigParamController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('configParam');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('configParam.detail', {
                parent: 'admin',
                url: '/configParam/{id}',
                data: {
                    authorities: ['ROLE_CONFIGURAZIONE_PARAM'],
                    pageTitle: 'cinecaLandingApp.configParam.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/configParam/configParam-detail.html',
                        controller: 'ConfigParamDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('configParam');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ConfigParam', function($stateParams, ConfigParam) {
                        return ConfigParam.get({id : $stateParams.id});
                    }]
                }
            })
            .state('configParam.new', {
                parent: 'configParam',
                url: '/new',
                data: {
                    authorities: ['ROLE_CONFIGURAZIONE_PARAM'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/configParam/configParam-dialog.html',
                        controller: 'ConfigParamDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    configurationId: null,
                                    name: null,
                                    value: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('configParam', null, { reload: true });
                    }, function() {
                        $state.go('configParam');
                    })
                }]
            })
            .state('configParam.edit', {
                parent: 'configParam',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_CONFIGURAZIONE_PARAM'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/configParam/configParam-dialog.html',
                        controller: 'ConfigParamDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['ConfigParam', function(ConfigParam) {
                                return ConfigParam.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('configParam', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('configParam.delete', {
                parent: 'configParam',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_CONFIGURAZIONE_PARAM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/configParam/configParam-delete-dialog.html',
                        controller: 'ConfigParamDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ConfigParam', function(ConfigParam) {
                                return ConfigParam.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('configParam', null, { reload: 'configParam' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
            
    }
})();
