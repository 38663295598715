(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('EventiDialogController', EventiDialogController);

    EventiDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'listaOrganizzatori', 'Eventi','Service','AnagraficaService'];

    function EventiDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, listaOrganizzatori, Eventi,Service,AnagraficaService) {
        var vm = this;

        if(entity.dataInizio != null)
        	entity.dataInizio = new Date(entity.dataInizio);
        if(entity.dataFine != null)
        	entity.dataFine = new Date(entity.dataFine);
        
        vm.evento = entity;
        vm.listaOrganizzatori = listaOrganizzatori;
        vm.clear = clear;
        vm.save = save;
        vm.addService = addService;
        vm.removeService = removeService;
        vm.serviceSelected = null;
        vm.addOrganizzatore = addOrganizzatore;
        vm.removeOrganizzatore = removeOrganizzatore;
        vm.organizzatoreSelected = null;
        
        
        vm.switchOptions = {
			onText : 'yes',
			offText : 'no', 
			size : 'medium',
			animate : true,
			radioOff : true,
			handleWidth : "auto",
			labelWidth : "auto",
			inverse : true,
			onColor : "unimib",
			offColor : "default"
		};
        
        
        
        
        
        getServices();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
          
            if (vm.evento.id !== null) {
                Eventi.update(vm.evento, onSaveSuccess, onSaveError);
            } else {
            	Eventi.save(vm.evento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:eventiUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        
        function getServices(){
        	Service.query({}, onSuccess, onError);
           
            function onSuccess(data, headers) {
                vm.listaServizi = data;
                
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        
        function addService($item, $model, $label){
        	if(vm.evento.idmEventiServices == null)
        		vm.evento.idmEventiServices = [];
        	
        	
        	var index = vm.evento.idmEventiServices.indexOf($item);
        	if(index==-1)
        		vm.evento.idmEventiServices.push($item);
        	vm.serviceSelected = null;
        }
        
        function removeService(servizio){
        	var index = vm.evento.idmEventiServices.indexOf(servizio);
        	vm.evento.idmEventiServices.splice(index, 1); 
        	
        	if(vm.evento.idmEventiServices.length==0)
        		vm.evento.richiedeApprovazione = false;
        	
        }

        function addOrganizzatore($item, $model, $label){
        	if(vm.evento.idmEventiOrganizzatori == null)
        		vm.evento.idmEventiOrganizzatori = [];

        	var index = vm.evento.idmEventiOrganizzatori.indexOf($item);
        	if(index==-1)
        		vm.evento.idmEventiOrganizzatori.push($item);
        	vm.organizzatoreSelected = null;
        }
        
        function removeOrganizzatore(org){
        	var index = vm.evento.idmEventiOrganizzatori.indexOf(org);
        	vm.evento.idmEventiOrganizzatori.splice(index, 1); 
        }

    }
})();
