(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'AnagraficaService','$state','$http','$window'];

    function HomeController ($scope, Principal, LoginService,AnagraficaService,$state,$http,$window) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.showEmailAlert = false;
        vm.mailIsMissing = false;
		vm.mailsAreEqual = false;
		vm.escapeSingleQuote = escapeSingleQuote;
		vm.setTextContrast = setTextContrast;
		vm.saveServiceViewPreference = saveServiceViewPreference;
		
		vm.profileIsOpen = false;
		vm.userIsOpen = true;
		vm.selfIsOpen = false;
		if($window.localStorage.getItem("SERVICE_LIST_VIEW")==null){
			vm.thList = "LIST";
			$window.localStorage.setItem("SERVICE_LIST_VIEW",vm.thList);
		}
		else
			vm.thList = $window.localStorage.getItem("SERVICE_LIST_VIEW");
		
        
        $scope.$on('authenticationSuccess', function() {
            getAccount();
           
        });

        getAccount();
          

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                
                if(vm.account){
//                	checkPersonalInformations();
                	getServicesList();
//                	getUserType();
                }
            });
        }
        function register () {
            $state.go('register');
        }
        
        function getUserType(){
        	$http.get('api/currentUserType').then(function(result) {
                
        		if(result.status == 200){
        			$scope.userType = result.data;
        		}
                
            });

        };
        
        
        function getServicesList(){
        	$http.get('api/userServiceList').then(function(result) {
                
        		if(result.status == 200){
        			$scope.userServices = result.data;
        		}
                
            });

        };
        
        function checkPersonalInformations(){
        	AnagraficaService.checkPersonalInformation.check(onSuccess,onError);
        	function onSuccess(data, headers) {
        		vm.mailIsMissing = data.MAIL_MISSING;
        		vm.mailsAreEqual = data.MAIL_EQUAL;
        		vm.showEmailAlert = vm.mailIsMissing || vm.mailsAreEqual;
            }
            function onError(error) {
            	console.log(error);
                //AlertService.error(error.data.message);
            }
        }
        
        function escapeSingleQuote(stringa){
        	stringa = stringa.replace(/'/g, "\\'");
        	return stringa;
        }
        
        
        function setTextContrast(color) {

        	if(color!=null){
        		var rgb = color.split(",");
        		rgb[0] = rgb[0].replace("rgb(","");
        		rgb[2] = rgb[2].replace(")","");

        		// http://www.w3.org/TR/AERT#color-contrast
        		var o = Math.round(((parseInt(rgb[0]) * 299) +
        				(parseInt(rgb[1]) * 587) +
        				(parseInt(rgb[2]) * 114)) / 1000);
        		var textColor = (o > 125) ? 'black' : 'white';
        		return {"color" : textColor, "font-weight":"bold"};
        	}
        	else{
        		return {"font-weight":"bold"};
        	}

        }
        
        function saveServiceViewPreference(){
        	vm.thList = vm.thList==="LIST" ? "IMAGE" : "LIST";
        	$window.localStorage.removeItem("SERVICE_LIST_VIEW");
        	$window.localStorage.setItem("SERVICE_LIST_VIEW",vm.thList);
        }
    }
})();
