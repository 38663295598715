(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Environment_trDialogController', Environment_trDialogController);

    Environment_trDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Environment_tr', 'Environment','LANGUAGES'];

    function Environment_trDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Environment_tr, Environment,LANGUAGES) {
        var vm = this;

        vm.environment_tr = entity;
        vm.clear = clear;
        vm.save = save;
        vm.environments = Environment.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.environment_tr.id !== null) {
                Environment_tr.update(vm.environment_tr, onSaveSuccess, onSaveError);
            } else {
                Environment_tr.save(vm.environment_tr, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:environment_trUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
