'use strict';

angular.module('cinecaLandingApp')
.factory('EsterniDurataService', ['$resource',function ($resource) {
	return {
		external: $resource('api/public/esterni-durata', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
		})
	};
}]);
