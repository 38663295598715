(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Module_trDetailController', Module_trDetailController);

    Module_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Module_tr', 'Module'];

    function Module_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Module_tr, Module) {
        var vm = this;

        vm.module_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('cinecaLandingApp:module_trUpdate', function(event, result) {
            vm.module_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
