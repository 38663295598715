(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .directive('inputLuogoNascita', inputLuogoNascita);

    function inputLuogoNascita () {
        var directive = {
            restrict: 'E',
            scope: {
    			esterni: '=',
    			updateLuogo: '&'
    		},
    		controller:['$scope','ComuniCodiceFiscaleService', function MyInputController($scope,ComuniCodiceFiscaleService) {
    			
    			$scope.currentluogoNascita = $scope.esterni.luogoNascita;
    			//function
    			$scope.getElencoComuni = function(){
    	    		ComuniCodiceFiscaleService.query({}, onSuccess, onError);

    	    		function onSuccess(data, headers) {
    	    			$scope.listaComuni = data;
    	    		}
    	    		function onError(error) {
    	    			AlertService.error(error.data.message);
    	    		}
    	    	}
    			
    	        $scope.appendSiglaProvincia = function(siglaProvincia){
    	        	if(siglaProvincia!=null)
    	        		return " ("+siglaProvincia+")";
    	        	else
    	        		return "";
    	        }
    	        //Action
    	    	$scope.getElencoComuni();
    			
    	    	
    		}],
    		templateUrl: 'app/components/form/input-luogo-nascita.tpl.html'
        };

        return directive;

        
    }
})();
