'use strict';

angular.module('cinecaLandingApp')
    .controller('RejectedTypeDetailController', 
    		['$scope', '$rootScope', '$stateParams', 'entity', 'RejectedType', 
    		function ($scope, $rootScope, $stateParams, entity, RejectedType) {
        $scope.rejectedType = entity;
        $scope.load = function (id) {
            RejectedType.get({id: id}, function(result) {
                $scope.rejectedType = result;
            });
        };
        $rootScope.$on('cinecaLandingApp:rejectedTypeUpdate', function(event, result) {
            $scope.rejectedType = result;
        });
    }]);
