(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Environment_trDeleteController',Environment_trDeleteController);

    Environment_trDeleteController.$inject = ['$uibModalInstance', 'entity', 'Environment_tr'];

    function Environment_trDeleteController($uibModalInstance, entity, Environment_tr) {
        var vm = this;

        vm.environment_tr = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Environment_tr.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
