(function() {
    'use strict';
    angular
        .module('cinecaLandingApp')
        .factory('Service', Service);

    Service.$inject = ['$resource'];

    function Service ($resource) {
        var resourceUrl =  'api/services/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll':{
            	method: 'GET',
            	url: 'api/get-all-services',
            	isArray: true
            },
            'authorize':{
            	method: 'POST',
            	url: 'api/registry/authorize',
            	isArray: false
            },
            'userDetails':{
            	method: 'POST',
            	url: 'api/registry/user-details',
            	isArray: false
            },
            'update': { method:'PUT' }
        });
    }
})();
