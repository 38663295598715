'use strict';

angular.module('cinecaLandingApp')
.controller('EsterniDialogController',
		['$scope', '$stateParams', '$uibModalInstance', 'entity', 'tipologieEsterniList','durataList','tipiDocIdentitaList','struttureList','Esterni','CodiceFiscaleService','$q','DATE_PICKER_MIN','DATE_PICKER_MAX', 
	        function($scope, $stateParams, $uibModalInstance, entity, tipologieEsterniList, durataList,tipiDocIdentitaList,struttureList,Esterni, CodiceFiscaleService,$q,DATE_PICKER_MIN,DATE_PICKER_MAX) {

    	var ctrl = this;
    	$scope.countryList = null;
    	$scope.genderList = ['M','F'];
    	$scope.checkIfDateIsBeforeToday = checkIfDateIsBeforeToday;
        $scope.checkIfDateIsInRange = checkIfDateIsInRange;
        
        $scope.dateFormat = "dd/MM/yyyy";
    	$scope.minDateAllowed = new Date(DATE_PICKER_MIN);
    	$scope.maxDateAllowed = new Date(DATE_PICKER_MAX);

    	function checkIfDateIsBeforeToday(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
        	var today = new Date();
        	today.setHours(0,0,0,0);
    		if(dateToVerify>=today)
    			deferred.resolve(isValid);
    		else
    			deferred.reject(false);
    			
    		
    		return deferred.promise;
    	};
    	
    	function checkIfDateIsInRange(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
    		if(dateToVerify<$scope.minDateAllowed || dateToVerify>$scope.maxDateAllowed)
    			deferred.reject(false);
    		else
    			deferred.resolve(isValid);
    		
    		return deferred.promise;
    	};
        

    	//Tipologie Esterni
    	$scope.tipologieEsterniList = tipologieEsterniList;
    	//Tipologie doc identità
    	$scope.tipiDocIdentitaList = tipiDocIdentitaList;
    	//Durata
    	$scope.durataList = durataList;
    	//Strutture
    	$scope.struttureList = struttureList;
    	$scope.strutturaPrimoLivello = null;
    	$scope.strutturaSecondoLivello = null;
    	$scope.strutturaTerzoLivello = null;
    	
    	//Fix formato date
    	if(entity.dataNascita!=null)
    		entity.dataNascita = new Date(entity.dataNascita);
    	if(entity.dataInizio!=null)
    		entity.dataInizio = new Date(entity.dataInizio);
    	else
    		entity.dataInizio = new Date();
    	if(entity.dataValiditaDocumento!=null)
    		entity.dataValiditaDocumento = new Date(entity.dataValiditaDocumento);
    	
    	//Gestione strutture
    	if(entity.struttura != null){
    		//Primo livello
    		if(entity.struttura.livello===1){
    			$scope.strutturaPrimoLivello = entity.struttura;
    		}
    		//Secondo livello
    		else if(entity.struttura.livello===2){
    			$scope.strutturaSecondoLivello = entity.struttura;
    			//Setto il primo
    			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
				})[0];
    		}
    		//Terzo livello
    		else{
    			
    			//Recupero il padre
    			var result = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===entity.struttura.idAbPadre;
				})[0];
    			if(result.livello===1){
    				$scope.strutturaSecondoLivello = entity.struttura;
    				$scope.strutturaPrimoLivello = result;
    			}
    			else{
    				$scope.strutturaTerzoLivello = entity.struttura;
    				$scope.strutturaSecondoLivello = result;
    				//Setto il primo
        			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
      				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
      				})[0];
    			}
    				
    			


    		}
    	}
    	
    	$scope.esterni = entity;
    	
    	$scope.today = new Date();
    	$scope.today.setDate($scope.today.getDate() - 1);
    	
    	Esterni.getCountryList({},function(response){
        	$scope.countryList = response;
        });
    	
    	
    	
    	if($scope.esterni.id != null)
    		$scope.savedCodFis = $scope.esterni.codiceFiscale;
    	else
    		$scope.savedCodFis = null;
       
        $scope.esterniCopy = {};
      
        
        if ( $stateParams.id !== undefined ) {
	        entity.$promise.then(function( ent ) {
	        	//entity.provResidenza = provinciaSelected;
	        	angular.copy(ent, $scope.esterniCopy);
	        });
        }

        $scope.load = function(id) {
            Esterni.get({id : id}, function(result) {
                $scope.esterni = result;
              
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('cinecaLandingApp:esterniUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function (formIsValid) {
        	if(formIsValid){
        		if($scope.esterni.fonte=='ESTERNO' || $scope.esterni.id==null){
	        		//Gestione strutture
	            	if($scope.strutturaTerzoLivello!=null)
	            		$scope.esterni.struttura = $scope.strutturaTerzoLivello;
	            	else if($scope.strutturaSecondoLivello!=null)
	            		$scope.esterni.struttura = $scope.strutturaSecondoLivello;
	            	else
	            		$scope.esterni.struttura = $scope.strutturaPrimoLivello;
	            	
	            	if($scope.luogoNascitaSelezionato!=null)
	            		$scope.esterni.luogoDiNascita = $scope.luogoNascitaSelezionato.denominazioneComune;
	            	
	                if ($scope.esterni.id != null) {
	                    Esterni.update($scope.esterni, onSaveFinished);
	                } else {
	                    Esterni.save($scope.esterni, onSaveFinished);
	                }
        		}
        		else{
        			Esterni.update($scope.esterni, onSaveFinished);
        		}
        	}
        	
        	
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        
        $scope.checkUniqueCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var payload = {
					"documentoIdentita": null,
					"codiceFiscale" : codfis,
					"nazionalita":"IT"
			}; 
        	
        	CodiceFiscaleService.checkUniqueCodiceFiscale.check( payload, function(resp) {
        		isValid = resp.isUnique;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		//Il codice fiscale esiste perchè è quello dell'utente corrente.
        		else if($scope.savedCodFis != null && $scope.savedCodFis === codfis){
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        
        $scope.checkCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkCodiceFiscale.check( codfis, function(resp) {
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        $scope.checkDateCodiceFiscale = function(dataNascita, codiceFiscale) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var inputDate =  (dataNascita == null ? new Date() : dataNascita);
        	var payload = {
					"dataNascita": transformDate( inputDate ),
					"codiceFiscale" : codiceFiscale
			}; 
        	CodiceFiscaleService.checkDateCodiceFiscale.check( payload, function(resp) {
        	
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        $scope.checkGenderCodFiscale = function(gender,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	
        	if(codiceFiscale.length>=10){
        		var giornoNascita = codiceFiscale.substring(9,11);
        		if( (giornoNascita>=40 && gender==='F') || (giornoNascita<40 && gender==='M') ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}
        	return deferred.promise;
        };
        
        $scope.checkLuogoCodFiscale = function(luogo,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	if(codiceFiscale.length==16 && luogo!=null){
        		var codiceComune = codiceFiscale.substring(11,15);
        		if(codiceComune===luogo ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        		
        	}
        	else{
        		isValid = true;
    			deferred.resolve(isValid);
        	}
        	
        	return deferred.promise;
        }
        
        $scope.startsWith = function(comune, viewValue) {
        	return comune.substring(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
        } 
        
        $scope.isActive = function(){
        	if($scope.esterni!=null && $scope.esterni.flAttivo==1)
        		return true;
        	return false;
        }
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        ctrl.preventKeyboard = function($event) {
    		$event.preventDefault();
    	}
        
        $scope.updateLuogoNascitaSelezionato = function(luogo){
        	$scope.luogoNascitaSelezionato = luogo.codice;
        }
//        $scope.updateLuogoNascitaSelezionato = function($item, $model, $label){
//        	$scope.luogoNascitaSelezionato = $item.codice;
//        }
//        $scope.appendSiglaProvincia = function(siglaProvincia){
//        	if(siglaProvincia!=null)
//        		return " ("+siglaProvincia+")";
//        	else
//        		return "";
//        }
        
        
        $scope.almenoUnaStruttura = function(){
        	if($scope.strutturaPrimoLivello==null&&$scope.strutturaSecondoLivello==null&&$scope.strutturaTerzoLivello==null)
        		return true;
        	
        	return false;
        };
   

        
      
}]);
