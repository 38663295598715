(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('fornitori', {
                parent: 'entity',
                url: '/fornitori?page&sort&search',
                data: {
                    authorities: ['ROLE_GESTIONE_FORNITORI'],
                    pageTitle: 'cinecaLandingApp.fornitori.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/fornitori/fornitori.html',
                        controller: 'FornitoriController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'cognome,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fornitori');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('fornitori.detail', {
                parent: 'entity',
                url: '/fornitori/{id}',
                data: {
                    authorities: ['ROLE_GESTIONE_FORNITORI'],
                    pageTitle: 'cinecaLandingApp.fornitori.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/fornitori/fornitori-detail.html',
                        controller: 'FornitoriDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fornitori');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Esterni', function($stateParams, Esterni) {
                        return Esterni.get({id : $stateParams.id});
                    }]
                }
            })
            .state('fornitori.new', {
                parent: 'fornitori',
                url: '/create/new',
                data: {
                    authorities: ['ROLE_GESTIONE_FORNITORI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/fornitori/fornitori-dialog.html',
                        controller: 'FornitoriDialogController',
                        controllerAs: 'FornitoriCtrl',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	id: null,
                                	dataCreazione: null,
                                	dataInizio: null,
                                	dataFine: null,
                                	dataEliminazione: null,
                                	dataValiditaDocumento: null,
                                	codiceFiscale: null,
                                	documentoIdendita: null,
                                	nazionalita: null,
                                	nome: null,
                                	cognome: null,
                                	genere: null,
                                	luogoNascita: null,
                                	emailPrivata: null,
                                	mobilePrivato: null,
                                	flAttivo: 0,
                                	dataNascita: null,
                                	durata: null,
                                	registratoSpid: 0,
                                	strutturaAfferenza: null,
                                	tipoDocumentoIdentita: null,
                                	tipologiaUtente: null,
                                	tipologiaFornitore: null,
                                	categoria: 0,
                                	fonte: 'FORNITORE'
                                };
                            },
                            'tipologieFornitoriList': ['TipologieFornitoriService', function(TipologieFornitoriService) {
                                return TipologieFornitoriService.external.get({}).$promise;
                            }],
                            'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
                                return TipiDocIdentitaService.external.get({}).$promise;
                            }],
                            'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                                return EsterniDurataService.external.get({}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                    	$state.go('fornitori', null, { reload: true });
                    }, function() {
                        $state.go('fornitori');
                    })
                }]
            })
            .state('fornitori.edit', {
                parent: 'fornitori',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_GESTIONE_FORNITORI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal','Esterni', function($stateParams, $state, $uibModal,Esterni) {
        			$uibModal.open({
                        templateUrl: 'app/entities/fornitori/fornitori-dialog.html',
                        controller: 'FornitoriDialogController',
                        controllerAs: 'FornitoriCtrl',
                        size: 'lg',
                        resolve: {
                            'tipologieFornitoriList': ['TipologieFornitoriService', function(TipologieFornitoriService) {
                                return TipologieFornitoriService.external.get({}).$promise;
                            }],
                            'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
                                return TipiDocIdentitaService.external.get({}).$promise;
                            }],
                            'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                                return EsterniDurataService.external.get({}).$promise;
                            }],
                            'entity': ['Esterni', function(Fornitori) {
                                return Fornitori.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('fornitori', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });

                }]
            })
            .state('fornitori.delete', {
	            parent: 'fornitori',
	            url: '/{id}/delete',
	            data: {
	                authorities: ['ROLE_GESTIONE_FORNITORI']
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/entities/fornitori/fornitori-delete-dialog.html',
	                    controller: 'FornitoriDeleteController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: ['Esterni', function(Esterni) {
	                            return Esterni.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('fornitori', null, { reload: 'fornitori' });
	                }, function() {
	                    $state.go('^');
	                });
	            }]
            });
            
    	}
})();
