(function() {
    'use strict';
    angular
        .module('cinecaLandingApp')
        .factory('Group', Group);

    Group.$inject = ['$resource'];

    function Group ($resource) {
        var resourceUrl =  'api/groups/:id';

        return $resource(resourceUrl, {}, {
            'getAll':{
            	method: 'GET',
            	url: 'api/get-all-groups',
            	isArray: true
            }
        });
    }
})();
