'use strict';

angular.module('cinecaLandingApp')
.controller('EsterniRegistrationController',
		['$stateParams', 'entity','durataList','tipiDocIdentitaList','Esterni','CodiceFiscaleService','StruttureService','$q','$scope','DATE_PICKER_MIN','DATE_PICKER_MAX', 
	        function($stateParams, entity, durataList,tipiDocIdentitaList,Esterni, CodiceFiscaleService,StruttureService,$q,$scope,DATE_PICKER_MIN,DATE_PICKER_MAX) {

		var vm = this;
    	
    	vm.countryList = null;
    	vm.genderList = ['M','F'];
    	vm.checkIfDateIsBeforeToday = checkIfDateIsBeforeToday;
        vm.checkIfDateIsInRange = checkIfDateIsInRange;
        
        vm.dateFormat = "dd/MM/yyyy";
    	vm.minDateAllowed = new Date(DATE_PICKER_MIN);
    	vm.maxDateAllowed = new Date(DATE_PICKER_MAX);
    	vm.checkConsenso = false;
    	vm.success = false;

    	function checkIfDateIsBeforeToday(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
        	var today = new Date();
        	today.setHours(0,0,0,0);
    		if(dateToVerify>=today)
    			deferred.resolve(isValid);
    		else
    			deferred.reject(false);
    			
    		
    		return deferred.promise;
    	};
    	
    	function checkIfDateIsInRange(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
    		if(dateToVerify<vm.minDateAllowed || dateToVerify>vm.maxDateAllowed)
    			deferred.reject(false);
    		else
    			deferred.resolve(isValid);
    		
    		return deferred.promise;
    	};
        

    	//Tipologie doc identità
    	vm.tipiDocIdentitaList = tipiDocIdentitaList;
    	//Durata
    	vm.durataList = durataList;
    	//Strutture
    	if(entity.struttura != null){
	    	StruttureService.external.getTree({idAb:entity.struttura.idAb},function(data){
	    		vm.strutturaPrimoLivello = data.primoLivello;
	    		vm.strutturaSecondoLivello = data.secondoLivello;
	        	vm.strutturaTerzoLivello = data.terzoLivello;
	    	});
    	}

    		
    	
    	
    	//Fix formato date
    	if(entity.dataInizio!=null)
    		entity.dataInizio = new Date(entity.dataInizio);
    	else
    		entity.dataInizio = new Date();
    	
//    	//Gestione strutture
//    	if(entity.strutturaAfferenza != null){
//    		//Primo livello
//    		if(entity.strutturaAfferenza.livello===1){
//    			vm.strutturaPrimoLivello = entity.strutturaAfferenza;
//    		}
//    		//Secondo livello
//    		else if(entity.strutturaAfferenza.livello===2){
//    			vm.strutturaSecondoLivello = entity.strutturaAfferenza;
//    			//Setto il primo
//    			vm.strutturaPrimoLivello = vm.struttureList.filter(function( obj ) {
//				  return obj.idAb===vm.strutturaSecondoLivello.idAbPadre;
//				})[0];
//    		}
//    		//Terzo livello
//    		else{
//    			
//    			//Recupero il padre
//    			var result = vm.struttureList.filter(function( obj ) {
//				  return obj.idAb===entity.strutturaAfferenza.idAbPadre;
//				})[0];
//    			if(result.livello===1){
//    				vm.strutturaSecondoLivello = entity.strutturaAfferenza;
//    				vm.strutturaPrimoLivello = result;
//    			}
//    			else{
//    				vm.strutturaTerzoLivello = entity.strutturaAfferenza;
//    				vm.strutturaSecondoLivello = result;
//    				//Setto il primo
//        			vm.strutturaPrimoLivello = vm.struttureList.filter(function( obj ) {
//      				  return obj.idAb===vm.strutturaSecondoLivello.idAbPadre;
//      				})[0];
//    			}
//    				
//    			
//
//
//    		}
//    	}
    	
    	vm.utenteEsterno = entity;
    	
    	vm.today = new Date();
    	vm.today.setDate(vm.today.getDate() - 1);
    	
    	Esterni.getCountryList({},function(response){
        	vm.countryList = response;
        });
    	

    	vm.savedCodFis = null;
        vm.utenteEsternoCopy = {};

        vm.save = function (formIsValid) {
        	if(formIsValid){
        		if(vm.utenteEsterno.fonte=='ESTERNO' || vm.utenteEsterno.id==null){
	        		//Gestione strutture
	            	if(vm.strutturaTerzoLivello!=null)
	            		vm.utenteEsterno.struttura = vm.strutturaTerzoLivello;
	            	else if(vm.strutturaSecondoLivello!=null)
	            		vm.utenteEsterno.struttura = vm.strutturaSecondoLivello;
	            	else
	            		vm.utenteEsterno.struttura = vm.strutturaPrimoLivello;
	            	
	            	if(vm.luogoNascitaSelezionato!=null)
	            		vm.utenteEsterno.luogoDiNascita = vm.luogoNascitaSelezionato.denominazioneComune;
	            	
	                if (vm.utenteEsterno.id != null) {
	                    Esterni.update(vm.utenteEsterno, onSaveFinished);
	                } else {
	                    Esterni.save(vm.utenteEsterno, onSaveFinished);
	                }
        		}
        		else{
        			Esterni.update(vm.utenteEsterno, onSaveFinished);
        		}
        	}
        	
        	
        };

        
        vm.checkUniqueCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var payload = {
					"documentoIdentita": null,
					"codiceFiscale" : codfis,
					"nazionalita":"IT"
			}; 
        	
        	CodiceFiscaleService.checkUniqueCodiceFiscale.check( payload, function(resp) {
        		isValid = resp.isUnique;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		//Il codice fiscale esiste perchè è quello dell'utente corrente.
        		else if(vm.savedCodFis != null && vm.savedCodFis === codfis){
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        
        vm.checkCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkCodiceFiscale.check( codfis, function(resp) {
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        vm.checkDateCodiceFiscale = function(dataNascita, codiceFiscale) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var inputDate =  (dataNascita == null ? new Date() : dataNascita);
        	var payload = {
					"dataNascita": transformDate( inputDate ),
					"codiceFiscale" : codiceFiscale
			}; 
        	CodiceFiscaleService.checkDateCodiceFiscale.check( payload, function(resp) {
        	
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        vm.checkGenderCodFiscale = function(gender,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	
        	if(codiceFiscale.length>=10){
        		var giornoNascita = codiceFiscale.substring(9,11);
        		if( (giornoNascita>=40 && gender==='F') || (giornoNascita<40 && gender==='M') ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}
        	return deferred.promise;
        };
        
        vm.checkLuogoCodFiscale = function(luogo,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	if(codiceFiscale.length==16 && luogo!=null){
        		var codiceComune = codiceFiscale.substring(11,15);
        		if(codiceComune===luogo ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        		
        	}
        	else{
        		isValid = true;
    			deferred.resolve(isValid);
        	}
        	
        	return deferred.promise;
        }
        
        vm.startsWith = function(comune, viewValue) {
        	return comune.substring(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
        } 
        
        vm.isActive = function(){
        	if(vm.utenteEsterno!=null && vm.utenteEsterno.flAttivo==1)
        		return true;
        	return false;
        }
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        vm.preventKeyboard = function($event) {
    		$event.preventDefault();
    	}
        
        vm.updateLuogoNascitaSelezionato = function(luogo){
        	vm.luogoNascitaSelezionato = luogo.codice;
        }

        
        vm.almenoUnaStruttura = function(){
        	if(vm.strutturaPrimoLivello==null&&vm.strutturaSecondoLivello==null&&vm.strutturaTerzoLivello==null)
        		return true;
        	
        	return false;
        };
        
        vm.checkIf16YearsOld = function(dateOfBirth){
    		
        	var ageDifMs = Date.now() - dateOfBirth.getTime();
        	var ageDate = new Date(ageDifMs);// miliseconds from epoch
     	    var age = (Math.abs(ageDate.getUTCFullYear() - 1970));
    		
     	    if(age>=16)
    			return true;
    		else
    			return false;

    	};
        
        
        vm.changeStep = function(value,formIsValid){
        	vm.btAvantiClicked = true;
        	if(formIsValid){
        		vm.stepConfirmation = value;
        		if(value===true){
        			vm.isAtLeast16YearsOld = vm.checkIf16YearsOld(vm.utenteEsterno.dataNascita);
        		}
        	}
        }
        
        vm.checkIfConsenso = function(){
    		if(vm.checkConsenso==true)
    			return true;
    		else
    			return false;
    	};
        
        
        
        vm.createAccount = function(){
        	vm.utenteEsterno.luogoDiNascita = vm.luogoNascitaSelezionato.denominazioneComune;
        	//Va verificato che l'utente non sia già presente
        	Esterni.newEsterniComplete(vm.utenteEsterno, onSaveFinished, onSaveError);
        };
        
        var onSaveFinished = function (result) {
            //Va mostrata una pagina con scritto "hai ricevuto un email..per confermare..."
        	vm.success = true;
        };
        
        var onSaveError = function (result) {
            //Va mostrata una pagina con scritto "hai ricevuto un email..per confermare..."
        	vm.success = false;
        };
   

        
      
}]);
