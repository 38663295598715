'use strict';

angular.module('cinecaLandingApp')
.factory('Policy', ['$resource',function ($resource) {
	return {
		policies: $resource('api/policies/:id', {}, {
			'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
		}),
		create: $resource('api/create-policy', {}, {
			'nuova': { method: 'POST',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
			
		})
	};
}])
.factory('UserAsset', ['$resource',function ($resource) {
	return $resource('api/user-assets', {}, {
		'get': { method: 'GET',
			params: {},
			isArray: true,
			transformResponse: function (data) {
				data = angular.fromJson(data);
				return data;
			}
		}
	});
}])
.factory('AssetProbe', ['$resource',function ($resource) {
	return $resource('api/asset-probes', {}, {
		'get': { method: 'GET',
			params: {},
			isArray: true,
			transformResponse: function (data) {
				data = angular.fromJson(data);
				return data;
			}
		},
		'getSettings': { method: 'GET',
			url: 'api/asset-probes-settings',
			params: {},
			isArray: false,
			transformResponse: function (data) {
				data = angular.fromJson(data);
				return data;
			}
		},
		'putSettings': { method: 'PUT',
			url: 'api/asset-probes-settings',
			params: {}
		},
		'start': { method: 'POST',
			url: 'api/asset-probe-start',
			params: {}
		}
	});
}]);


//(function() {
//    'use strict';
//    angular
//        .module('cinecaLandingApp')
//        .factory('Policy', Policy);
//
//    Policy.$inject = ['$resource'];
//
//    function Policy ($resource) {
//        var resourceUrl =  'api/policies/:id';
//
//        return $resource(resourceUrl, {}, {
//            'query': { method: 'GET', isArray: true},
//            'get': {
//                method: 'GET',
//                transformResponse: function (data) {
//                    if (data) {
//                        data = angular.fromJson(data);
//                    }
//                    return data;
//                }
//            },
//            'update': { method:'PUT' },
//            'create':{
//            	method: 'POST',
//            	url: 'api/create-policy',
//            	params: {},
//				isArray: true,
//            }
//        });
//    }
//})();



