'use strict';

angular.module('cinecaLandingApp')
	.factory('EsterniStatiSpecService', ['$resource',function ($resource) {
		return {
			stati: $resource('api/esterniStatiSpecifici', {}, {
				'get': { method: 'GET',
							params: {},
							isArray: true,
							transformResponse: function (data) {
								data = angular.fromJson(data);
								return data;
							}
                }
			})
		};
	}]);
