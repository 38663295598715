'use strict';

angular
.module('cinecaLandingApp')
.controller('VirtualUserDialogController',
		['$scope', '$stateParams', '$uibModalInstance', 'entity','struttureList','tipologieTelefonoList','UtenzeImpersonali','$q', 
	        function($scope, $stateParams, $uibModalInstance, entity,struttureList,tipologieTelefonoList,UtenzeImpersonali ,$q) {

    	var ctrl = this;

    	//Strutture
    	$scope.struttureList = struttureList;
    	$scope.strutturaPrimoLivello = null;
    	$scope.strutturaSecondoLivello = null;
    	$scope.strutturaTerzoLivello = null;
    	//Tipologie telefono
    	$scope.tipologieTelefonoList = tipologieTelefonoList;

    	//Gestione strutture
    	if(entity.struttura != null){
    		//Primo livello
    		if(entity.struttura.livello===1){
    			$scope.strutturaPrimoLivello = entity.struttura;
    		}
    		//Secondo livello
    		else if(entity.struttura.livello===2){
    			$scope.strutturaSecondoLivello = entity.struttura;
    			//Setto il primo
    			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
				})[0];
    		}
    		//Terzo livello
    		else{
    			
    			//Recupero il padre
    			var result = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===entity.struttura.idAbPadre;
				})[0];
    			if(result.livello===1){
    				$scope.strutturaSecondoLivello = entity.struttura;
    				$scope.strutturaPrimoLivello = result;
    			}
    			else{
    				$scope.strutturaTerzoLivello = entity.struttura;
    				$scope.strutturaSecondoLivello = result;
    				//Setto il primo
        			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
      				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
      				})[0];
    			}
    		}
    	}
    	
    	$scope.virtualUser = entity;
    	
    	
    	

        var onSaveFinished = function (result) {
            $scope.$emit('cinecaLandingApp:VirtualUserUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function (formIsValid) {
        	if(formIsValid){
	        	//Gestione strutture
	        	if($scope.strutturaTerzoLivello!=null)
	        		$scope.virtualUser.struttura = $scope.strutturaTerzoLivello;
	        	else if($scope.strutturaSecondoLivello!=null)
	        		$scope.virtualUser.struttura = $scope.strutturaSecondoLivello;
	        	else
	        		$scope.virtualUser.struttura = $scope.strutturaPrimoLivello;
	            if ($scope.virtualUser.id != null) {
	                UtenzeImpersonali.update($scope.virtualUser, onSaveFinished);
	            } else {
	                UtenzeImpersonali.save($scope.virtualUser, onSaveFinished);
	            }
        	}
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        $scope.isValidUnimibMail = function(email) { 
        	var mailPattern = /^[a-zA-Z0-9._-]+@unimib.it$/; 
        	return mailPattern.test(email) ;
        }
        
        ctrl.preventKeyboard = function($event) {
    		$event.preventDefault();
    	}

      
}]);
