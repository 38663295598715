(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth'];

    function RequestResetController ($timeout, Auth) {
        var vm = this;

        vm.error = null;
        vm.errorEmailNotExists = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;
        
        vm.type = 2;

        $timeout(function (){angular.element('#email').focus();});

        function requestReset () {

            vm.error = null;
            vm.errorEmailNotExists = null;
            vm.success = null;
            
            var objRequest = {};
            //Email
            if(vm.type==1)
            	objRequest.mail = vm.resetAccount.email;
            //userId
            else if(vm.type==2)
            	objRequest.userId = vm.resetAccount.userId;
            //codiceFiscale
            else if(vm.type==3)
            	objRequest.codiceFiscale = vm.resetAccount.codiceFiscale;

            Auth.resetPasswordInit(objRequest).then(function () {
                vm.success = 'OK';
            }).catch(function (response) {
                if (response.status === 400 && response.data === 'email address not registered') {
                    vm.errorEmailNotExists = 'ERROR';
                } 
                else if (response.status === 400 && response.data === 'private mail not found') {
                    vm.errorPrivateEmailNotExists = 'ERROR';
                }
                
                else {
                    vm.error = 'ERROR';
                }
            });
        }
    }
})();
