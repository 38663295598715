'use strict';

angular.module('cinecaLandingApp')
    .factory('Omonimie', ['$resource',function ($resource) {
    	  return $resource('api/omonimie/:username', {}, {
            'get': {
                method: 'GET'
             }
        });
    }]);
