'use strict';

angular.module('cinecaLandingApp')
    .factory('ScheduledJob', ['$resource','DateUtils',function ($resource, DateUtils) {
        return $resource('api/scheduledJobs/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    //data.dateStart = DateUtils.convertDateTimeFromServer(data.dateStart);
                    //data.dateEnd = DateUtils.convertDateTimeFromServer(data.dateEnd);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }])
	.factory('ScheduledJobErrors', ['$resource','DateUtils',function ($resource, DateUtils) {
	    return $resource('api/scheduledJobs/errors', {}, {
	        'query': { method: 'GET', isArray: true}
	    });
	}]);