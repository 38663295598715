(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboard', {
           parent: 'admin',
           url: '/dashboard',
           data: {
               authorities: ['ROLE_DASHBOARD_PROVISIONING'],
               pageTitle: 'global.menu.dashboard.main'
           },
           views: {
               'content@': {
                   templateUrl: 'app/dashboard/dashboard.html',
                   controller: 'DashboardController'
               }
           },
           resolve: {
               mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                   $translatePartialLoader.addPart('dashboard');
                   $translatePartialLoader.addPart('global');
                   return $translate.refresh();
               }]
           }
       });
    }
})();