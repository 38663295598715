(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Tenant_trDetailController', Tenant_trDetailController);

    Tenant_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tenant_tr', 'Tenant'];

    function Tenant_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Tenant_tr, Tenant) {
        var vm = this;

        vm.tenant_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('cinecaLandingApp:tenant_trUpdate', function(event, result) {
            vm.tenant_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
