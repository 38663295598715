(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .filter('filtroTabellaEsterni', filtroTabellaEsterni)
        .filter('filtroTabellaMailbox', filtroTabellaMailbox)
        .filter('filtroTabellaVirtualUser', filtroTabellaVirtualUser)
        .filter('filterPrimoLivello', filterPrimoLivello)
        .filter('filterSecondoLivello', filterSecondoLivello)
        .filter('filterTerzoLivello', filterTerzoLivello);

    	//Filtro per ricerca Esterni, Fornitori
	    function filtroTabellaEsterni() {
	    	return function(dataArray, searchTerm, typeFilter) {
			      if (!dataArray) {
			          return;
			      }
			      else if (!searchTerm && !typeFilter) {
			          return dataArray;
			      }
			      else {
			           
			           
			           return dataArray.filter(function(item){
			        	  var textCondition = true;
			        	  var typeCondition = true;
			        	  if(searchTerm!=null && searchTerm.length>0){
			        		  var text = searchTerm.toLowerCase();
			        		  var nameMatch = item.nome.toLowerCase().indexOf(text) > -1;
				              var surnameMatch = item.cognome.toLowerCase().indexOf(text) > -1;
				              var emailMatch = item.emailPrivata.toLowerCase().indexOf(text) > -1;
				              var userIdMatch = item.userId!=null ? (item.userId.toLowerCase().indexOf(text) > -1) : false;
				              var codFisMatch;
				              if(item.codiceFiscale!=null)
				            	  codFisMatch = item.codiceFiscale.toLowerCase().indexOf(text) > -1;
				              else
				            	  codFisMatch = item.documentoIdentita.toLowerCase().indexOf(text) > -1;
				            	  
				              textCondition = nameMatch || surnameMatch || emailMatch || codFisMatch || userIdMatch;
			        		  
			        	  }
			        	  if(typeFilter!=null && typeFilter.length>0){
			        		  var type = typeFilter.toLowerCase();
			        		  if(item.tipologiaUtente!=null)
			        			  typeCondition = item.tipologiaUtente.tipologia.toLowerCase().indexOf(type) > -1;
		        			  else if(item.tipologiaFornitore!=null)
		        				  typeCondition = item.tipologiaFornitore.tipologia.toLowerCase().indexOf(type) > -1;
		        			  else
			        			  typeCondition = item.fonte.toLowerCase().indexOf(type) > -1;
			        	  }
			        	  
			        	  return textCondition && typeCondition;
			           });
			      } 
			  }    
	    }
	    
	    //Filtro per la ricerca mailbox
	    function filtroTabellaMailbox(){
        	return function(dataArray, searchTerm) {
		    	if (!dataArray) {
		    		return;
		    	}
		    	else if (!searchTerm) {
		    		return dataArray;
		    	}
		    	else {
		    		return dataArray.filter(function(item){
		    			var text = searchTerm.toLowerCase();
		    			var usernameMatch = item.userName.toLowerCase().indexOf(text) > -1;
		    			var denominazioneMatch = item.denominazione.toLowerCase().indexOf(text) > -1;
		    			var emailMatch = item.emailResponsabile.toLowerCase().indexOf(text) > -1;
		    			return usernameMatch || denominazioneMatch || emailMatch;
		    		});
		    	} 
        	}    
		}
	    
	  //Filtro per la ricerca utenti virtuali
	    function filtroTabellaVirtualUser(){
	    	return function(dataArray, searchTerm, typeFilter) {
			      if (!dataArray) {
			          return;
			      }
			      else if (!searchTerm && !typeFilter) {
			          return dataArray;
			      }
			      else {
			           return dataArray.filter(function(item){
			        	  var textCondition = true;
			        	  var typeCondition = true;
			        	  if(searchTerm!=null && searchTerm.length>0){
			        		  var text = searchTerm.toLowerCase();
			        		  var userNameMatch = item.userName.toLowerCase().indexOf(text) > -1;
				              var descriptionMatch = item.descrizioneServizio.toLowerCase().indexOf(text) > -1;
				              var strutturaMatch = item.struttura.descrizione.toLowerCase().indexOf(text) > -1;

				              textCondition = userNameMatch || descriptionMatch || strutturaMatch;
			        		  
			        	  }
			        	  if(typeFilter!=null){
			        		  typeCondition = (item.tipologiaTelefono.id === typeFilter);
			        	  }
			        	  
			        	  return textCondition && typeCondition;
			           });
			      } 
	    	}
	    }
	    
	    
	    
	    function filterPrimoLivello() {
	        return function (strutture) {
	            var struttureFiltrate = [];
	            angular.forEach(strutture, function (strutt) {
	                if (strutt.livello===1) {
	                	struttureFiltrate.push(strutt);
	                }
	            });
	            return struttureFiltrate;        
	        }
	    }
	    
	    function filterSecondoLivello() {
	        return function (strutture,strutturaPrimoLivello) {
	        	var struttureFiltrate = [];
	    	    angular.forEach(strutture, function (strutt) {
	                if (strutt.livello!=1) {
	                	if(strutturaPrimoLivello!=null){
	    	            	var idAbPrimo = strutturaPrimoLivello.idAb;
	    	            	if(strutt.idAbPadre===idAbPrimo)
	    	            		struttureFiltrate.push(strutt);
	                	}
	                	else
	                		struttureFiltrate.push(strutt);
	                }
	            });
	            return struttureFiltrate;         
	        }
	    }
	    function filterTerzoLivello() {
	        return function (strutture,strutturaSecondoLivello) {
	        	var struttureFiltrate = [];
	            angular.forEach(strutture, function (strutt) {
	                if (strutt.livello===3) {
	                	if(strutturaSecondoLivello!=null){
	    	            	var idAbSecondo = strutturaSecondoLivello.idAb;
	    	            	if(strutt.idAbPadre===idAbSecondo)
	    	            		struttureFiltrate.push(strutt);
	                	}
	                	else
	                		struttureFiltrate.push(strutt);
	                }
	            });
	            return struttureFiltrate;     
	        }
	    }
})();
