(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('TestPolicyDialogController', TestPolicyDialogController);

    
    
    
    
    TestPolicyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance','AnagraficaService','Service'];
    // , 'Policy', 'Group', 'IdmCategoria','IdmStatiGenerali'

    function TestPolicyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, AnagraficaService, Service) {
    	//, Policy, Group, IdmCategoria,IdmStatiGenerali
        var vm = this;
        vm.clear = clear;
        vm.loadListaUtenti = loadListaUtenti;
        vm.selectUtente = selectUtente;
        vm.testPolicies = testPolicies;
        vm.typing = true;
        vm.services = [];
        vm.attributes = {};
        
        $scope.$watch('vm.selectedUser', function (newValue) {
        	vm.typing = true;
        	vm.services = [];
        	vm.attributes = {};
        });

        function testPolicies(){
        	vm.typing = false;
        	Service.authorize({"account":vm.selectedUser, "environmentName":"prod", "tenantCode":"Cineca"}, function(result) {
        		vm.services = (result.authorizedService && result.authorizedService.ids) ? result.authorizedService.ids : [];
        	}, function(error) {
        	});
        	Service.userDetails({"account":vm.selectedUser}, function(result) {
        		vm.attributes = {};
        		angular.forEach(result, function(value, key) {
                    if (/^(nome|cognome|mail|userId|categoria|employeeNumber|mmEnabledService|uid|ou|givenName|objectClass|o|employeeType|mmActive|adminGroups|groups|businessCategory)$/.test(key)) {
                    	vm.attributes[key] = value;
                    }
                });
        	}, function(error) {
        	});
        }
        
        function selectUtente($item, $model, $label){
        	if ($item && $item.userId) {
        		vm.selectedUser = $item.userId;
        	} else {
            	vm.selectedUser = "";
        	}
        }
        
        function loadListaUtenti(testo){
        	
        	if(testo.length>=5){
	            return AnagraficaService.anagrafiche.get({filtroTesto:testo}).$promise
        	}
        	else{
        		return [];
        	}

        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

//        var inputChangedPromise;
//        vm.save = save;
//        vm.switchMode = switchMode;
//        vm.whyPolicyIsIncorrect = null;
//        vm.policyIsCorrect = null;
//        vm.addService = addService;
//        vm.removeService = removeService;
//        vm.addCategory = addCategory;
//        vm.addPosition = addPosition;
//        vm.removeCategory = removeCategory;
//        vm.removePosition = removePosition;
//        vm.addGroup = addGroup;
//        vm.removeGroup = removeGroup;
////        vm.getCategorie = getCategorie;
////        vm.getSottoCategorie = getSottoCategorie;
////        vm.getStatiSpecifici = getStatiSpecifici;
////        vm.addRegola = addRegola;
////        vm.removeRegola = removeRegola;
//        vm.addUtente = addUtente;
//        vm.addNuovoUtente = addNuovoUtente;
//        vm.removeUtente = removeUtente;
//        vm.atLeastOneService = atLeastOneService;
//        
//        vm.policy = entity;
//        
//        vm.listaPosizioni = [{posizione: "DIPENDENTE", id:"1"}, {posizione: "ESTERNO", id:"2"}, {posizione: "ALTRO", id:"3"}];
//        
//        //Modifica
//        if(vm.policy.id!=null){
//        	vm.regole = vm.policy.regole;
//        	vm.policyUsers = vm.policy.utenti;
//        	vm.policyServices = vm.policy.servizi;
//        	vm.policyCategories = vm.policy.categorie;
//        	vm.policyPositions = vm.policy.posizioni.map(function(x){
//        		var flt = vm.listaPosizioni.filter(function(y){ return y.posizione == x;})
//        		return (flt && flt.length > 0) ? flt[0] : {posizione: x};
//        	});
//        	vm.policyGroups = vm.policy.gruppi.map(function(x){ return {gruppo: x};});
//        }
//        //Nuovo
//        else{
//        	vm.regole = [];
//        	vm.policyUsers = [];
//        	vm.policyServices = [];
//        	vm.policyCategories = [];
//        	vm.policyPositions = [];
//        	vm.policyGroups = [];
//        }
//        
//        //Modalità guidata
//        if(vm.policy.flInserimentoGuidato || vm.policy.id==null){
//        	/*Elenco dei servizi*/
//            Service.getAll({}, function(result){
//            	vm.listaServizi = result;
//            },
//            function(response){
//            	console.log(response);
//            });
//            
//        	/*Elenco dei gruppi*/
//            Group.getAll({}, function(result){
//            	vm.listaGruppi = result;
//            },
//            function(response){
//            	console.log(response);
//            });
//            
//            /*Elenco categorie*/
//            IdmCategoria.query({}, function(result){
//            	vm.listaCategorie = result;
//            },
//            function(response){
//            	console.log(response);
//            });
//            
            /*Elenco stati generali*/
//            IdmStatiGenerali.query({}, function(result){
//            	vm.listaStatiGenerali = result;
//            },
//            function(response){
//            	console.log(response);
//            });
//        }
        
        
        
        

        
        
       
        

//        function save () {
//            vm.isSaving = true;
//            var obj = {
//        		servizi: vm.policyServices,
//        		utenti: vm.policyUsers,
//        		categorie: vm.policyCategories,
//        		posizioni: vm.policyPositions.map(function(x){return x.posizione;}),
//        		gruppi: vm.policyGroups.map(function(x){return x.gruppo;}),
//        		regole: vm.regole,
//        		name: vm.policy.name,
//        		description: vm.policy.description,
//        		policyXACML: vm.policy.policyXACML,
//        		flInserimentoGuidato: vm.policy.flInserimentoGuidato,
//        		id: vm.policy.id,
//        		isActive: vm.policy.isActive
//        	};
//            
//            
//            //Policy inserita in maniera testuale
//            if(!vm.policy.flInserimentoGuidato){
//            	if (vm.policy.id !== null) {
//            		Policy.update(obj, onSaveSuccess, onSaveError);
//                } else {
//                	Policy.save(obj, onSaveSuccess, onSaveError);
//                }
//            }
//            //Policy inserita in maniera guidata
//            else{
//
//            	if (vm.policy.id !== null)
//            		Policy.update(obj, onSaveSuccess, onSaveError);
//            	else
//            		Policy.create(obj, onSaveSuccess, onSaveError);
//            }
//            
//            
//            
//        }
//
//        function onSaveSuccess (result) {
//            $scope.$emit('cinecaLandingApp:policyUpdate', result);
//            $uibModalInstance.close(result);
//            vm.isSaving = false;
//        }
//
//        function onSaveError () {
//            vm.isSaving = false;
//        }
        
//        //Aggiunge un nuovo utente, se non è stato trovato dall'autocompletamento
//        function addNuovoUtente(){
//        	if(vm.selectedUser!=null && vm.selectedUser!=""){
//		    	if(vm.policyUsers == null)
//		    		vm.policyUsers = [];
//    	
//		    	var utente = {
//		    		userId: vm.selectedUser,
//		    		nome: null,
//		    		cognome: null
//		    	};
//		    	var index = vm.policyUsers.indexOf(utente);
//		    	if(index==-1)
//		    		vm.policyUsers.push(utente);
//		    	vm.selectedUser = null;
//        	}
//        }
//        //Rimuove l'utente selezionato
//        function removeUtente(utente){
//        	var index = vm.policyUsers.indexOf(utente);
//        	vm.policyUsers.splice(index, 1); 
//        	
//        }
        
        
    }
})();
