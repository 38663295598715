(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('distribution-list', {
            parent: 'entity',
            url: '/distribution-list',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE'],
                pageTitle: 'cinecaLandingApp.distribution_list.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribution-list/distribution-lists.html',
                    controller: 'Distribution_listController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('distribution_list');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('distribution-list-detail', {
            parent: 'distribution-list',
            url: '/detail/{id}',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE'],
                pageTitle: 'cinecaLandingApp.distribution_list.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribution-list/distribution-list-detail.html',
                    controller: 'Distribution_listDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('distribution_list');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Distribution_list', function($stateParams, Distribution_list) {
                    return Distribution_list.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'distribution-list',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('distribution-list-detail.edit', {
            parent: 'distribution-list-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-list/distribution-list-dialog.html',
                    controller: 'Distribution_listDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Distribution_list', function(Distribution_list) {
                            return Distribution_list.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('distribution-list.new', {
            parent: 'distribution-list',
            url: '/new',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-list/distribution-list-dialog.html',
                    controller: 'Distribution_listDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('distribution-list', null, { reload: 'distribution-list' });
                }, function() {
                    $state.go('distribution-list');
                });
            }]
        })
        .state('distribution-list.edit', {
            parent: 'distribution-list',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	if($stateParams.id>1){
	                $uibModal.open({
	                    templateUrl: 'app/entities/distribution-list/distribution-list-dialog.html',
	                    controller: 'Distribution_listDialogController',
	                    controllerAs: 'vm',
	                    backdrop: 'static',
	                    size: 'lg',
	                    resolve: {
	                        entity: ['Distribution_list', function(Distribution_list) {
	                            return Distribution_list.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('distribution-list', null, { reload: 'distribution-list' });
	                }, function() {
	                    $state.go('^');
	                });
            	}
            }]
        })
        .state('distribution-list.delete', {
            parent: 'distribution-list',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	if($stateParams.id>1){
	            	$uibModal.open({
	                    templateUrl: 'app/entities/distribution-list/distribution-list-delete-dialog.html',
	                    controller: 'Distribution_listDeleteController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: ['Distribution_list', function(Distribution_list) {
	                            return Distribution_list.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('distribution-list', null, { reload: 'distribution-list' });
	                }, function() {
	                    $state.go('^');
	                });
            	}
            }]
        });
    }

})();
