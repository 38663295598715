(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Service_trDetailController', Service_trDetailController);

    Service_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Service_tr', 'Service'];

    function Service_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Service_tr, Service) {
        var vm = this;

        vm.service_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('cinecaLandingApp:service_trUpdate', function(event, result) {
            vm.service_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
