(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Environment_trDetailController', Environment_trDetailController);

    Environment_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Environment_tr', 'Environment'];

    function Environment_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Environment_tr, Environment) {
        var vm = this;

        vm.environment_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('cinecaLandingApp:environment_trUpdate', function(event, result) {
            vm.environment_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
