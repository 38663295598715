(function () {
    'use strict';
    angular
        .module('cinecaLandingApp')
        .constant('DATE_PICKER_MIN', "01/01/1900")
        .constant('DATE_PICKER_MAX', calcolateMaxDate())
        .constant('LOCAL_LOGIN', true);
        
        function calcolateMaxDate(){
    		var dateLimit = new Date();
    		dateLimit.setFullYear(dateLimit.getFullYear() - 14);
    		return dateLimit.toString();
    	}

})();
