(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Provider_trDialogController', Provider_trDialogController);

    Provider_trDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Provider_tr', 'Provider','LANGUAGES'];

    function Provider_trDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Provider_tr, Provider,LANGUAGES) {
        var vm = this;

        vm.providerId = $stateParams.id;
        vm.provider_tr = entity;
        vm.clear = clear;
        vm.save = save;
        vm.providers = Provider.query();
        vm.languages = LANGUAGES;
        

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.provider_tr.id !== null) {
                Provider_tr.update(vm.provider_tr, onSaveSuccess, onSaveError);
            } else {
                Provider_tr.save(vm.provider_tr, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:provider_trUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
