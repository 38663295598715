(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .directive('associazioneAnagrafica', associazioneAnagrafica);

    function associazioneAnagrafica () {
        var directive = {
            restrict: 'E',
            scope: {
    			users: '='
    		},
    		controller:['$scope','AnagraficaService', function MyInputController($scope,AnagraficaService) {
    			var vm = this;
    			$scope.loadListaUtenti = loadListaUtenti;
    			$scope.addUtente = addUtente;
    			$scope.removeUtente = removeUtente;
    		
    	        
    	        function loadListaUtenti(testo){
    	        	
    	        	if(testo.length>=5){
    		            return AnagraficaService.anagrafiche.get({filtroTesto:testo}).$promise
    	        	}
    	        	else{
    	        		return [];
    	        	}

    	        }
    	        //Aggiunge l'utente selezionato dall'autocompletamento
    	        function addUtente($item, $model, $label){
    	        	if($scope.users == null)
    	        		$scope.users = [];
    	        	
    	        	var index = $scope.users.indexOf($item);
    	        	if(index==-1)
    	        		$scope.users.push($item);
    	        	$scope.selectedUser = null;
    	        }
    	        //Rimuove l'utente selezionato
    	        function removeUtente(utente){
    	        	var index = $scope.users.indexOf(utente);
    	        	$scope.users.splice(index, 1); 
    	        	
    	        }

    	    	
    		}],
    		templateUrl: 'app/components/form/associazione-anagrafica.tpl.html'
        };

        return directive;

        
    }
})();
