(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .filter('fromNow', FromNowFilter)
    	.filter('readableDate', ReadableDate())
    	.filter('readableTime', ReadableDate("LT"))
    	;
   
    function FromNowFilter() {
        return fromNow;

        function fromNow(date) {
        	return moment(date).fromNow();
        }
    }
    function ReadableDate(format) {
    	return function() {
            return dt;
            
            function dt(date) {
            	return date ? moment(date).format(format || "L LT") : "";
            }
    	}
    }
})();
