(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .directive('hasAuthority', hasAuthority)
        .directive('hasAuthorityException', hasAuthorityException);

    hasAuthority.$inject = ['Principal'];
    hasAuthorityException.$inject = ['Principal'];

    function hasAuthority(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var authority = attrs.hasAuthority.replace(/\s+/g, '');

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    Principal.hasAuthority(authority)
                        .then(function (result) {
                            if (result) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            if (authority.length > 0) {
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
    }
    
    function hasAuthorityException(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
        	var attrToArray = scope.$eval(attrs.hasAuthorityException);
        	var authorityMustHaveNot = attrToArray[1].replace(/\s+/g, '');
            var authorityMustHave = attrToArray[0].replace(/\s+/g, '');
            

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }
                    
                    var has = Principal.hasAnyAuthority(authorityMustHave.split(","));
                    
                    if(!has)
                    	setHidden();
                    else if(Principal.hasAnyAuthority(authorityMustHaveNot.split(","))){
                    	setHidden();
                    }
                    else{
                    	setVisible();
                    } 
                    
                };

            if (authorityMustHave.length > 0 && authorityMustHaveNot.length > 0) {
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
        
        function checkIfHasAuthority(authority){
        	
        }
        
        function checkIfHasNOTAuthority(authority){
        	
        }
    }
    
    
    
})();
