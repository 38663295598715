'use strict';

angular.module('cinecaLandingApp')
    .factory('RejectedRow', ['$resource', 'DateUtils',function ($resource, DateUtils) {
        return $resource('api/rejectedRows/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }])
    .factory('RejectedRowFilter', ['$resource','DateUtils',function ($resource, DateUtils) {
	    return $resource('api/rejectedRows/idJobList', {}, {
	        'query': { method: 'GET', isArray: true}
	    });
	}]);
