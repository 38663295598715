(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('esterni-register', {
            parent: 'entity',
            url: '/esterni-registration/{token}',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'content@': {
                	templateUrl: 'app/entities/esterni/registration/esterni-registration.html',
                    controller: 'EsterniRegistrationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('esterni');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Esterni', function($stateParams, Esterni) {
                    return Esterni.getRequest({requestToken : $stateParams.token}).$promise;
                }],
                'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
                    return TipiDocIdentitaService.external.get({}).$promise;
                }],
                'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                    return EsterniDurataService.external.get({}).$promise;
                }]
            }
        })
        
    }
})();
