(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
            	//if (response.data!=null && response.data.message!=null && response.data.message.indexOf('policy-invalid')===-1)
            	if (response.data!=null && response.data.message!=null && response.data.message.indexOf('policy-invalid')>-1){
            		
            	}
            	else{
            		$rootScope.$emit('cinecaLandingApp.httpError', response);
            	}
            		
            }
            return $q.reject(response);
        }
    }
})();
