'use strict';

angular.module('cinecaLandingApp').controller('EccezioniController', 
		['$scope', 'Eccezioni', 'ParseLinks', function ($scope, Eccezioni, ParseLinks) {
        $scope.eccezionis = [];
        $scope.page = 0;
        $scope.loadAll = function() {
            Eccezioni.query({page: $scope.page, size: 20}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.eccezionis = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            Eccezioni.get({id: id}, function(result) {
                $scope.eccezioni = result;
                $('#deleteEccezioniConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            Eccezioni.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteEccezioniConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.eccezioni = {
                codFis: null,
                fonte: 'UGOV',
                username: null,
                id: null
            };
        };
    }]);
