'use strict';

angular.module('cinecaLandingApp')
    .controller('RejectedRowDetailController', 
    		['$scope', '$rootScope', '$stateParams', 'entity', 'RejectedRow', 'RejectedType', 
    		function($scope, $rootScope, $stateParams, entity, RejectedRow, RejectedType) {
        $scope.rejectedRow = entity;
        $scope.load = function (id) {
            RejectedRow.get({id: id}, function(result) {
                $scope.rejectedRow = result;
            });
        };
        $rootScope.$on('cinecaLandingApp:rejectedRowUpdate', function(event, result) {
            $scope.rejectedRow = result;
        });
    }]);
