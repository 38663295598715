(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('GestioneUtentiGruppiController', GestioneUtentiGruppiController);

    GestioneUtentiGruppiController.$inject = ['$state','$sce','$location','CINECA_USERS_GROUPS_URLS','CINECA_USERS_GROUPS_URLS_PP' ];

    function GestioneUtentiGruppiController($state, $sce, $location, CINECA_USERS_GROUPS_URLS, CINECA_USERS_GROUPS_URLS_PP) {

        var vm = this;
        
        var urls = CINECA_USERS_GROUPS_URLS;
    	if ($location.host().match(/\.pp\./)) {
    		urls = CINECA_USERS_GROUPS_URLS_PP;
    	}

        
        vm.usersUrl = $sce.trustAsResourceUrl(urls.USERS);
        vm.groupsUrl = $sce.trustAsResourceUrl(urls.GROUPS);
        vm.attributesUrl = $sce.trustAsResourceUrl(urls.ATTRIBUTES);
        
    }
})();
