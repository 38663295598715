(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('PolicyController', PolicyController);

    PolicyController.$inject = ['$scope', '$state', 'Policy', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PolicyController($scope, $state, Policy, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.currentSearch = pagingParams.search;
		vm.currentSearch.isActive = 0;
		$scope.searchActiveOptions = [
			{name: "Nessun filtro", value: 0},
			{name: "Filtra per disattivati", value: 1},
			{name: "Filtra per attivi", value: 2},
		];
        vm.loadAll = loadAll;
        vm.getPolicies = getPolicies;
        
        loadAll();

        function loadAll () {
            Policy.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                textFilter: vm.currentSearch ? vm.currentSearch.nameFilter : null,
                /*
                 * searchActive = 0 == ricerca per stato di attivazione disabilitato
                 * searchActive = 1 == ricerca per stato di attivazione disattivo
                 * searchActive = 2 == ricerca per stato di attivazione attivo
                */
                searchActive: vm.currentSearch.isActive
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.policies = getPolicies(data);
                //vm.page = pagingParams.page;
                console.log("onSuccess");
                console.log(pagingParams);
                console.log(vm);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function getPolicies(data) {
        	return data;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	vm.loadAll();
        	console.log(vm);
        	console.log(pagingParams);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                textFilter: vm.currentSearch ? vm.currentSearch.nameFilter : null,
                /*
                 * searchActive = 0 == ricerca per stato di attivazione disabilitato
                 * searchActive = 1 == ricerca per stato di attivazione disattivo
                 * searchActive = 2 == ricerca per stato di attivazione attivo
                */
                searchActive: vm.currentSearch.isActive
            }).then(function () {
            	console.log(vm);
            	vm.loadAll();
            });
        }
        
        function onSaveSuccess (result) {
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        vm.toggleActivationSearch = function() {
        	console.log(vm);
/*        	vm.currentSearch.isActive++;
        	if (vm.currentSearch.isActive == 3) {
        		vm.currentSearch.isActive = 0;
        	}*/
        	loadAll();
        }
        
        vm.toggleActivation = function(policy){
        	policy.isActive = !policy.isActive;
        	var obj = {
        		id: policy.id,
        		activate: policy.isActive
        	}
        	
        	Policy.activate(obj, onSaveSuccess, onSaveError);
        } ;
    }
})();
