(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .directive('onReadFile',['$parse', function ($parse) {
		    return {
		        restrict: 'A',
		        scope: false,
		        link: function(scope, element, attrs) {
		            element.bind('change', function(e) {
		                
		                var onFileReadFn = $parse(attrs.onReadFile);
		                var reader = new FileReader();
		                
		                reader.onload = function() {
		                    var fileContents = reader.result;
		                    // invoke parsed function on scope
		                    // special syntax for passing in data
		                    // to named parameters
		                    // in the parsed function
		                    // we are providing a value for the property 'contents'
		                    // in the scope we pass in to the function
		                    scope.$apply(function() {
		                        onFileReadFn(scope, {
		                            'contents' : fileContents
		                        });
		                    });
		                };
		                reader.readAsText(element[0].files[0]);
		            });
		        }
		    };
		}])
        .controller('PolicyDialogController', PolicyDialogController);

    
    
    
    
    PolicyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Policy', 'Service', 'Group', 'IdmCategoria','IdmStatiGenerali','AnagraficaService'];

    function PolicyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Policy, Service, Group, IdmCategoria,IdmStatiGenerali,AnagraficaService) {
        var vm = this;

        var inputChangedPromise;
        vm.clear = clear;
        vm.save = save;
        vm.switchMode = switchMode;
        vm.whyPolicyIsIncorrect = null;
        vm.policyIsCorrect = null;
        vm.addService = addService;
        vm.removeService = removeService;
        vm.addCategory = addCategory;
        vm.addPosition = addPosition;
        vm.removeCategory = removeCategory;
        vm.removePosition = removePosition;
        vm.addGroup = addGroup;
        vm.removeGroup = removeGroup;
//        vm.getCategorie = getCategorie;
//        vm.getSottoCategorie = getSottoCategorie;
//        vm.getStatiSpecifici = getStatiSpecifici;
//        vm.addRegola = addRegola;
//        vm.removeRegola = removeRegola;
        vm.addUtente = addUtente;
        vm.addNuovoUtente = addNuovoUtente;
        vm.removeUtente = removeUtente;
        vm.loadListaUtenti = loadListaUtenti;
        vm.atLeastOneService = atLeastOneService;
        
        vm.policy = entity;
        
        vm.listaPosizioni = [{posizione: "DIPENDENTE", id:"1"}, {posizione: "ESTERNO", id:"2"}, {posizione: "ALTRO", id:"3"}];
        
        //Modifica
        if(vm.policy.id!=null){
        	vm.regole = vm.policy.regole;
        	vm.policyUsers = vm.policy.utenti;
        	vm.policyServices = vm.policy.servizi;
        	vm.policyCategories = vm.policy.categorie;
        	vm.policyPositions = vm.policy.posizioni.map(function(x){
        		var flt = vm.listaPosizioni.filter(function(y){ return y.posizione == x;})
        		return (flt && flt.length > 0) ? flt[0] : {posizione: x};
        	});
        	vm.policyGroups = vm.policy.gruppi.map(function(x){ return {gruppo: x};});
        }
        //Nuovo
        else{
        	vm.regole = [];
        	vm.policyUsers = [];
        	vm.policyServices = [];
        	vm.policyCategories = [];
        	vm.policyPositions = [];
        	vm.policyGroups = [];
        }
        
        //Modalità guidata
        if(vm.policy.flInserimentoGuidato || vm.policy.id==null){
        	/*Elenco dei servizi*/
            Service.getAll({}, function(result){
            	vm.listaServizi = result;
            },
            function(response){
            	console.log(response);
            });
            
        	/*Elenco dei gruppi*/
            Group.getAll({}, function(result){
            	vm.listaGruppi = result;
            },
            function(response){
            	console.log(response);
            });
            
            /*Elenco categorie*/
            IdmCategoria.query({}, function(result){
            	vm.listaCategorie = result;
            },
            function(response){
            	console.log(response);
            });
            
            /*Elenco stati generali*/
//            IdmStatiGenerali.query({}, function(result){
//            	vm.listaStatiGenerali = result;
//            },
//            function(response){
//            	console.log(response);
//            });
        }
        
        
        
        

        
        
       
        
        /**** Editor di testo ****/
        $scope.aceLoaded = function(_editor){
        	if(vm.policy.policyXACML!=null)
        		$scope.verificaPolicy();
			// Editor part
			var _session = _editor.getSession();
			var _renderer = _editor.renderer;
			// Options
			_renderer.setShowGutter(true);
			_session.setUseWrapMode(true);
			_session.setMode('ace/mode/xml');
			_session.setUndoManager(new ace.UndoManager());
			_editor.setHighlightActiveLine(true);
			_editor.setHighlightSelectedWord(true);
			_editor.setShowPrintMargin(false);
			_editor.setTheme('ace/theme/tomorrow');
			_editor.on("change", $scope.aceChanged);

		};
		
		$scope.aceChanged = function(){
			if(inputChangedPromise){
		        $timeout.cancel(inputChangedPromise);
		    }
		    inputChangedPromise = $timeout($scope.verificaPolicy,1000);
			
		};
		
		$scope.verificaPolicy = function() {
			Policy.verify(vm.policy, function(response){
				if(response.code===200){
					 vm.policyIsCorrect = true;
					 vm.whyPolicyIsIncorrect = null;
				}
				else{
					vm.policyIsCorrect = false;
					vm.whyPolicyIsIncorrect = response.message;
				}
			},
			function(response){
				vm.policyIsCorrect = false;
				vm.whyPolicyIsIncorrect = response.data.description;
			});
	    };
	    

		$scope.displayFileContents = function(contents) {
			vm.policy.policyXACML = contents;
	    };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var obj = {
        		servizi: vm.policyServices,
        		utenti: vm.policyUsers,
        		categorie: vm.policyCategories,
        		posizioni: vm.policyPositions.map(function(x){return x.posizione;}),
        		gruppi: vm.policyGroups.map(function(x){return x.gruppo;}),
        		regole: vm.regole,
        		name: vm.policy.name,
        		description: vm.policy.description,
        		policyXACML: vm.policy.policyXACML,
        		flInserimentoGuidato: vm.policy.flInserimentoGuidato,
        		id: vm.policy.id,
        		isActive: vm.policy.isActive
        	};
            
            
            //Policy inserita in maniera testuale
            if(!vm.policy.flInserimentoGuidato){
            	if (vm.policy.id !== null) {
            		Policy.update(obj, onSaveSuccess, onSaveError);
                } else {
                	Policy.save(obj, onSaveSuccess, onSaveError);
                }
            }
            //Policy inserita in maniera guidata
            else{

            	if (vm.policy.id !== null)
            		Policy.update(obj, onSaveSuccess, onSaveError);
            	else
            		Policy.create(obj, onSaveSuccess, onSaveError);
            }
            
            
            
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:policyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        //Cambia da "inserimento manuale" a "inserimento guidato" (e viceversa)
        function switchMode(value){
        	vm.policy.flInserimentoGuidato = value;
        }
        
        //Aggiunge un servizio, chiamata all'onClick sull'autocompletamento
        function addService($item, $model, $label){
        	if(vm.policyServices == null)
        		vm.policyServices = [];
        	
        	var index = vm.policyServices.indexOf($item);
        	if(index==-1)
        		vm.policyServices.push($item);
        	vm.selectedService = null;
        }
        //Rimuove il servizio selezionato
        function removeService(servizio){
        	var index = vm.policyServices.indexOf(servizio);
        	vm.policyServices.splice(index, 1); 
        	
        }
        //Aggiunge una categoria, chiamata all'onClick sull'autocompletamento
        function addCategory($item, $model, $label){
        	if(vm.policyCategories == null)
        		vm.policyCategories = [];
        	
        	var index = vm.policyCategories.indexOf($item);
        	if(index==-1)
        		vm.policyCategories.push($item);
        	vm.selectedCategory = null;
        }
        //Rimuove la categoria selezionata
        function removeCategory(categoria){
        	var index = vm.policyCategories.indexOf(categoria);
        	vm.policyCategories.splice(index, 1); 
        	
        }
        
        //Aggiunge una categoria, chiamata all'onClick sull'autocompletamento
        function addPosition($item, $model, $label){
        	if(vm.policyPositions == null)
        		vm.policyPositions = [];
        	
        	var index = vm.policyPositions.indexOf($item);
        	if(index==-1)
        		vm.policyPositions.push($item);
        	vm.selectedPosition = null;
        }
        //Rimuove la categoria selezionata
        function removePosition(posizione){
        	var index = vm.policyPositions.indexOf(posizione);
        	vm.policyPositions.splice(index, 1); 
        	
        }
        
        //Aggiunge una categoria, chiamata all'onClick sull'autocompletamento
        function addGroup($item, $model, $label){
        	if(vm.policyGroups == null)
        		vm.policyGroups = [];
        	
        	var index = vm.policyGroups.indexOf($item);
        	if(index==-1)
        		vm.policyGroups.push($item);
        	vm.selectedGroup = null;
        }
        //Rimuove la categoria selezionata
        function removeGroup(gruppo){
        	var index = vm.policyGroups.indexOf(gruppo);
        	vm.policyGroups.splice(index, 1); 
        	
        }
        
        
//        //Recupera l'elenco delle categorie, in base alla macroCategoria selezionata
//        function getCategorie(){
//        	if(vm.currentMacroCat!=null)
//        		vm.listaCategorie = vm.currentMacroCat.figli;
//        	else
//        		vm.listaCategorie = [];
//
//        }
//        //Recupera l'elenco delle sottoCategorie, in base alla categoria selezionata
//        function getSottoCategorie(){
//        	if(vm.currentCategoria!=null)
//        		vm.listaSottoCategorie = vm.currentCategoria.figli;
//        	else
//        		vm.listaSottoCategorie = [];
//        }
//        //Recupera l'elenco degli stati specifici, in base allo stato generale selezionato
//        function getStatiSpecifici(){
//        	if(vm.currentStatoGenerale!=null)
//        		vm.listaStatiSpecifici = vm.currentStatoGenerale.statiSpecifici;
//        	else
//        		vm.listaStatiSpecifici = [];
//        }
//        
//        //Aggiunge la regola
//        function addRegola(){
//        	var object = {};
//        	if(vm.currentMacroCat!=null)
//        		object.macroCategoria = vm.currentMacroCat.descrizione;
//        	if(vm.currentCategoria!=null)
//        		object.categoria = vm.currentCategoria.descrizione;
//        	if(vm.currentSottoCat!=null)
//        		object.sottoCategoria = vm.currentSottoCat.descrizione;
//        	if(vm.currentStatoGenerale!=null)
//        		object.statoGenerale = vm.currentStatoGenerale.statoGenerale;
//        	
//        	object.statoSpecifico = vm.currentStatoSpecifico;
//        	
//        	if(vm.regole.indexOf(object)==-1)
//        		vm.regole.push(object);
//        	
//        	//Pulisco i campi
//        	vm.currentMacroCat = null;
//        	vm.currentCategoria = null;
//        	vm.currentSottoCat = null;
//        	vm.currentStatoGenerale = null;
//        	vm.currentStatoSpecifico = null;
//        }
//        //Rimuove la regola selezionata
//        function removeRegola(regola){
//        	var index = vm.regole.indexOf(regola);
//        	vm.regole.splice(index, 1); 
//        }
        //Aggiunge l'utente selezionato dall'autocompletamento
        function addUtente($item, $model, $label){
        	if(vm.policyUsers == null)
        		vm.policyUsers = [];
        	
        	var index = vm.policyUsers.indexOf($item);
        	if(index==-1)
        		vm.policyUsers.push($item);
        	vm.selectedUser = null;
        }
        //Aggiunge un nuovo utente, se non è stato trovato dall'autocompletamento
        function addNuovoUtente(){
        	if(vm.selectedUser!=null && vm.selectedUser!=""){
		    	if(vm.policyUsers == null)
		    		vm.policyUsers = [];
    	
		    	var utente = {
		    		userId: vm.selectedUser,
		    		nome: null,
		    		cognome: null
		    	};
		    	var index = vm.policyUsers.indexOf(utente);
		    	if(index==-1)
		    		vm.policyUsers.push(utente);
		    	vm.selectedUser = null;
        	}
        }
        //Rimuove l'utente selezionato
        function removeUtente(utente){
        	var index = vm.policyUsers.indexOf(utente);
        	vm.policyUsers.splice(index, 1); 
        	
        }
        
        function loadListaUtenti(testo){
        	
        	if(testo.length>=5){
	            return AnagraficaService.anagrafiche.get({filtroTesto:testo}).$promise
        	}
        	else{
        		return [];
        	}

        }
        
        function atLeastOneService(){
        	if(vm.policy.flInserimentoGuidato && (vm.policyServices==null || vm.policyServices.length==0))
        		return false;
        	return true;
        }

    }
})();
