(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('Iso_country_codesDialogController', Iso_country_codesDialogController);

    Iso_country_codesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Iso_country_codes'];

    function Iso_country_codesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Iso_country_codes) {
        var vm = this;

        vm.iso_country_codes = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.iso_country_codes.id !== null) {
                Iso_country_codes.update(vm.iso_country_codes, onSaveSuccess, onSaveError);
            } else {
                Iso_country_codes.save(vm.iso_country_codes, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cinecaLandingApp:iso_country_codesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
