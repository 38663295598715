(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('EsterniController', EsterniController);

    EsterniController.$inject = ['$state','$scope', 'Esterni', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function EsterniController($state, $scope, Esterni, ParseLinks, AlertService, paginationConstants, pagingParams) {

		var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        
        vm.tipologiePerFiltro = ["Collaboratore esterno",
                                 "Cultore",
                                 "Esercitatore o tutor",
                                 "Jira",
                                 "Visiting professor",
                                 "Visitatore"
                                 ];
        
			
		$scope.esternis = [];
        $scope.page = 0;
        
        loadAll();
        
        function loadAll() {
            Esterni.query({
            		page: pagingParams.page - 1,
                	size: vm.itemsPerPage,
                	sort: sort()
            }, onSuccess, onError);
            
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                $scope.esternis = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        vm.activateUser = function(esterno,toActivate){
        	
        	
        	Esterni.activate({id: esterno.id, toActivate:toActivate},onSuccess,onError);
        	
        	function onSuccess(data, headers) {
        		esterno.flAttivo= toActivate ? 1 : 0;
        		esterno.statoSpecifico = data.statoSpecifico;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        
        vm.calcolaDataFine = function(esterno){
    		var dataFine = new Date(esterno.dataInizio);
    		if(esterno.durata!=null)
    			dataFine.setDate(dataFine.getDate() + esterno.durata.valore);
    		return dataFine;
    	}
        
    }
})();