(function() {
    'use strict';

    angular
        .module('cinecaLandingApp')
        .controller('RegistrationConfirmedController', RegistrationConfirmedController);


    RegistrationConfirmedController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService','Esterni','$stateParams'];

    function RegistrationConfirmedController ($translate, $timeout, Auth, LoginService, Esterni,$stateParams) {
        var vm = this;
        
        vm.success = false;
        
        var token = $stateParams.token;
        Esterni.confirmRegistration({'token':token},function(){
        	vm.success = true;
        });
    }
    
})();
